import { Flex } from '@applyboard/crystal-ui';
import { useFilterField, MIN_SUBMISSION_DATE_FILTER_VALUE } from '../../../hooks';
import { DatePicker } from '../../../components/uie-fix'
import { endOfDay } from 'date-fns'

function calculateLowerBoundDate() {
  return new Date(MIN_SUBMISSION_DATE_FILTER_VALUE).toISOString();
}

function calculateUpperBoundDate(date: string) {
  const EOD = endOfDay(date).getTime();
  return EOD <= Date.now() ? EOD : Date.now() - 1;
}

function hasValueChanged(newValue: string | undefined, oldValue: string | undefined): boolean {
  return (newValue ?? '') !== (oldValue ?? '');
}

type SubmissionDateFilterType = 'from' | 'to';

function getValue(submissionDateFilterType: SubmissionDateFilterType, filterValue: { from?: string; to?: string }) {
  if (submissionDateFilterType === 'from') {
    return filterValue?.from && filterValue?.from !== 'undefined' ? new Date(filterValue.from).toISOString() : '';
  } else if (submissionDateFilterType === 'to') {
    return filterValue?.to && filterValue?.to !== 'undefined' ? new Date(filterValue.to).toISOString() : '';
  } else throw new Error(`${submissionDateFilterType} is not implemented`);
}

export function SubmissionDateFilter() {
  const { value, setValue: setSubmissionDate } = useFilterField('submissionDate');

  return (
    <Flex gap={2}>
      <Flex role="grid" aria-labelledby="startDate">
        <DatePicker
          granularity="day"
          helpText=""
          maxDate={value.to && value.to !== 'undefined' ? new Date(value.to).toISOString() : new Date().toISOString()}
          minDate={calculateLowerBoundDate()}
          onChange={(val) => {
            if (hasValueChanged(val, value.from)) {
              setSubmissionDate({
                ...value,
                from: val.length ? new Date(val).toISOString() : '',
              });
            }
          }}
          size="md"
          value={getValue('from', value)}
        />
      </Flex>
      <Flex role="grid" aria-labelledby="endDate">
        <DatePicker
          granularity="day"
          helpText=""
          maxDate={new Date().toISOString()}
          minDate={value.from && value.from !== 'undefined' ? new Date(value.from).toISOString() : calculateLowerBoundDate()}
          onChange={(val) => {
            if (hasValueChanged(val, value.to)) {
              setSubmissionDate({
                ...value,
                to: val.length ? new Date(calculateUpperBoundDate(val)).toISOString() : '',
              });
            }
          }}
          size="md"
          value={getValue('to', value)}
        />
      </Flex>
    </Flex>
  );
}
