import { useEffect, useState, useRef } from 'react'
import { Button, Flex, Pagination, Text } from '@applyboard/crystal-ui'
import { ApplicationsPageProps, useFilterField } from '../../hooks'
import { usePageProps } from '../../context'
import { ChevronLeftOutlineIcon, ChevronRightOutlineIcon } from '@applyboard/ui-icons'
import { flushSync } from 'react-dom'
import { breakpoints } from '../../utils'

interface ApplicationsPaginationProps {
  totalApplications: number
  isFetchingApplications: boolean
}

export function ApplicationsPagination({
  totalApplications,
  isFetchingApplications,
}: ApplicationsPaginationProps) {
  const { referenceData } = usePageProps<ApplicationsPageProps>();

  const pageNumberFilter = useFilterField('pageNumber')
  const pageSizeFilter = useFilterField('pageSize')

  const [pageChangeTriggered, setPageChangeTriggered] = useState(false)
  const [isMobileMode, setIsMobileMode] = useState(false);

  const paginationContainerRef = useRef<HTMLDivElement | null>(null)
  const desktopPrevPageButtonRef = useRef<HTMLButtonElement | null>(null)
  const desktopNextPageButtonRef = useRef<HTMLButtonElement | null>(null)
  const mobilePrevButtonRef = useRef<HTMLButtonElement | null>(null);
  const mobileNextButtonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileMode(window.innerWidth <= breakpoints.sm); // Example threshold
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (pageChangeTriggered && !isFetchingApplications) {
      const currentPage = pageNumberFilter.value
      const totalPages = Math.ceil(totalApplications / pageSizeFilter.value)

      // Focus restoration logic after data fetching is done
      if (document.activeElement === document.body) {
        let pageButtonToFocus: HTMLButtonElement | null = null;
        if (currentPage >= totalPages) {
          pageButtonToFocus = isMobileMode ? mobilePrevButtonRef.current : desktopPrevPageButtonRef.current;
        } else {
          pageButtonToFocus = isMobileMode ? mobileNextButtonRef.current : desktopNextPageButtonRef.current;
        }
        if (pageButtonToFocus) {
          pageButtonToFocus.focus();
        } 
      } else {
        // Manual scrolling logic to ensure the button is visible
        const focusedElement = document.activeElement as HTMLElement
        const rect = focusedElement.getBoundingClientRect();
        const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
        if (!isVisible) {
          window.scrollTo({
            top: rect.top + window.scrollY - window.innerHeight / 2,
            behavior: 'smooth',
          });
        }
      }

      setPageChangeTriggered(false)
    }
  }, [
    isFetchingApplications,
    pageChangeTriggered,
    totalApplications,
    pageNumberFilter.value,
    pageSizeFilter.value,
    isMobileMode,
  ])

  useEffect(() => {
    if (paginationContainerRef.current) {
      // Find the pagination buttons within Crystal UI Pagination component
      desktopNextPageButtonRef.current = paginationContainerRef.current.querySelector('[aria-label="Next page"]')
      desktopPrevPageButtonRef.current = paginationContainerRef.current.querySelector('[aria-label="Previous page"]')
    }
  }, []) // Run only once on mount

  return (
    <>
      <Flex hideBelow='sm' align="center" grow={1}>
        <Flex.Item grow={1}>
          <div ref={paginationContainerRef}>
            <Pagination
              numItems={totalApplications}
              currentPage={pageNumberFilter.value}
              pageSize={pageSizeFilter.value}
              onPageSizeChange={pageSize => {
                pageSizeFilter.setValue(pageSize)
              }}
              disabled={false}
              onPageChange={pageNumber => {
                pageNumberFilter.setValue(pageNumber)
                // Set page change trigger to handle the focus
                setPageChangeTriggered(true)
              }}
              variant="items"
              pageSizeOptions={referenceData.pageSizes}
            />
          </div>
        </Flex.Item>
      </Flex>
      <Flex hideAbove='sm' align="center" grow={1}>
        <Flex.Item grow={1}>
          <MobilePagination
            numItems={totalApplications}
            currentPage={pageNumberFilter.value}
            pageSize={pageSizeFilter.value}
            onPageChange={pageNumber => {
              pageNumberFilter.setValue(pageNumber)
              // Set page change trigger to handle the focus
              setPageChangeTriggered(true)
            }}
            mobilePrevButtonRef={mobilePrevButtonRef}
            mobileNextButtonRef={mobileNextButtonRef}
          />
        </Flex.Item>
      </Flex>
    </>
  )
}

interface MobilePaginationProps {
  numItems: number
  currentPage: number
  pageSize: number
  disabled?: boolean
  onPageChange: (value: number) => void
  mobilePrevButtonRef: React.RefObject<HTMLButtonElement>;
  mobileNextButtonRef: React.RefObject<HTMLButtonElement>;
}

export function MobilePagination({
  numItems,
  currentPage,
  pageSize,
  disabled,
  onPageChange,
  mobilePrevButtonRef,
  mobileNextButtonRef,
}: MobilePaginationProps) {
  const lastPage = Math.ceil(numItems / pageSize);

  return (
    <Flex gap={4} align="center" justify={'between'}>
      <Flex gap={2} align="center">
        <Text>
          {numItems === 0 ? 0 : (currentPage - 1) * pageSize + 1} - {Math.min(numItems, currentPage * pageSize)} of {numItems} rows
        </Text>
      </Flex>
      <Flex gap={2} align="center">
        <Button
          aria-label="Previous page"
          intent="secondary"
          leadIcon={ChevronLeftOutlineIcon}
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage <= 1 || disabled}
          emphasis="highlighted"
          ref={mobilePrevButtonRef}
        />
        <Button
          aria-label="Next page"
          intent="secondary"
          leadIcon={ChevronRightOutlineIcon}
          onClick={() => {
            const newPage = currentPage + 1;
            flushSync(() => onPageChange(newPage));
            if (newPage === lastPage && mobilePrevButtonRef?.current) {
              mobilePrevButtonRef.current.focus();
            }
          }}
          disabled={currentPage >= lastPage || disabled}
          emphasis="highlighted"
          ref={mobileNextButtonRef}
        />
      </Flex>
    </Flex>
  )
}


