export function convertToUrlSearchParams(params: Record<string, string | number | string[]>, baseKey?: string) {
  const queryParams = {} as Record<string, string>;
  Object.entries(params).forEach(([k, v]) => {
    const key = (baseKey && `${baseKey}[${k}]`) || k;
    if (IsNullOrUndefined(v)) {
      return;
    }
    if (typeof v === 'string' && v.length > 0) {
      queryParams[key] = v;
    } else if (typeof v === 'number') {
      queryParams[key] = `${v}`;
    } else if (typeof v === 'boolean' && v) {
      queryParams[key] = 't';
    } else if (Array.isArray(v) && v.length > 0) {
      queryParams[key] = v.join(',');
    }
  });
  return new URLSearchParams(queryParams);
};

function IsNullOrUndefined(value: unknown): value is null | undefined {
  return value === null || value === undefined;
}
