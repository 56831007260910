import { useAuthMutation } from './../auth'
import { ApiAgentRequest, request } from '../../utils'
import { RefinedVersionedApplicationResourceWithFiles } from 'applications-types-lib'

type SubmitApplicationProps = {
  id: string
}

type SubmitApplicationParams = undefined

type RawApplicationsResponse = {
  data: RefinedVersionedApplicationResourceWithFiles
}

export function useSubmitApplication(props: SubmitApplicationProps) {
  const { isPending, mutate } = useAuthMutation(async (params: SubmitApplicationParams) => {
    return await request<RawApplicationsResponse>(
      new ApiAgentRequest(`/applications/${props.id}/submit`, {
        method: 'POST',
      }),
      {
        isExpectedResponse: (res): res is RawApplicationsResponse => res,
      },
    )
  })

  return {
    isSubmittingApplication: isPending,
    submitApplication: mutate,
  }
}