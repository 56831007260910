import { Text } from '@applyboard/crystal-ui'
import styled from '@emotion/styled'

type ApplicationDetailCardHelperProps = Readonly<{
  text: string
  intent?: 'positive' | 'negative' | 'secondary'
}>

export function ApplicationDetailCardHelper(props: ApplicationDetailCardHelperProps) {
  return (
    <HelperWrapper intent={props.intent ?? 'secondary'}>
      <Text variant="bodyS">{props.text}</Text>
    </HelperWrapper>
  )
}

type HelperWrapperProps = {
  intent: 'positive' | 'negative' | 'secondary'
}

const HelperWrapper = styled.div<HelperWrapperProps>(({ theme, intent }) => ({
  borderLeftWidth: 4,
  borderLeftStyle: 'solid',
  borderLeftColor:
    intent === 'positive'
      ? theme.colors.iconPositiveHover
      : theme.colors[`icon${intent.charAt(0).toUpperCase() + intent.slice(1)}`],
  paddingBlock: 8,
  paddingLeft: 24,
}))
