import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { isArray } from "lodash";
import { useSearchParams } from "react-router-dom";
import { usePageProps } from "./PagePropsContext";
import { ApplicationsPageProps } from "../hooks";
import { QueryDefinitions, QueryDefinitionsAsArray, QueryParameters } from "./types";

export type QueryParameterContext<T extends QueryParameters> = {
  queryParameters: T;
  resetQueryParameters: () => void;
  setQueryParameters: (changes: Partial<T>) => void;
};

const queryParameterContext = createContext<any | undefined>(undefined);

export interface QueryParameterContextProps<T extends QueryParameters, SQ> {
  children: React.ReactNode;
  initialQueryParameters: Partial<T>
  queryDefinitions: {
    definitions: QueryDefinitions<T, SQ>,
    definitionsAsArray: QueryDefinitionsAsArray<T, SQ>,
  }
}

// set the filter fields on instantiation -> defines the schema of the filters
export function QueryParameterContextProvider<T extends QueryParameters, SQ>({
  children,
  initialQueryParameters,
  queryDefinitions,
}: QueryParameterContextProps<T, SQ>) {

  const staticProps = usePageProps<ApplicationsPageProps>();
  const [searchParams, setSearchParams] = useSearchParams()
  const [queryParameters, setSearchQueryParameters] = useState<T>(getDefaultQueryParameters())

  useEffect(() => {
    setSearchQueryParameters(queryParameters);
  }, [queryParameters])

  function getDefaultQueryParameters(): T {
    type PartialRecord<K extends keyof QueryParameters, V> = { [P in K]?: V };
    const defaultValues: PartialRecord<keyof QueryParameters, unknown> = {};
    queryDefinitions.definitionsAsArray.forEach((parameter) => {
      let value = parameter.getDefaultValue(searchParams);
      defaultValues[parameter.key] = value;
    });
    return defaultValues as T;
  }

  const setQueryParameters = useCallback(
    (changes: Partial<QueryParameters>, reset = false) => {
      const baseParams = reset ? { ...initialQueryParameters } : { ...queryParameters };
      const result = { ...baseParams, ...changes } as Partial<QueryParameters>;

      queryDefinitions.definitionsAsArray.forEach((parameter) => {
        if (!result[parameter.key] && !initialQueryParameters.hasOwnProperty(parameter.key)) {
          result[parameter.key] = '';
        }
      });

      setSearchParams(currSearchParams => {
        Object.entries(result).forEach(([key, value]) => {
          if (key !== 'submissionDate' && (!value || (isArray(value) && !value.length))) {
            currSearchParams.delete(key);
          } else if (key === 'submissionDate') {
            if (value?.to) {
              currSearchParams.set('submissionDateEnd', encodeURIComponent(value.to));
            } else {
              currSearchParams.delete('submissionDateEnd');
            }

            if (value?.from) {
              currSearchParams.set('submissionDate', encodeURIComponent(value.from));
            } else {
              currSearchParams.delete('submissionDate');
            }
          } else {
            currSearchParams.set(key, encodeURIComponent(value));
          }
        });

        return currSearchParams;
      });

      setSearchQueryParameters(result as T);
    },
    [queryDefinitions.definitionsAsArray, queryParameters, setSearchParams, initialQueryParameters]
  );

  const resetQueryParameters = useCallback(() => {
    setQueryParameters(staticProps.initialQueryParameters, true);
  }, [setQueryParameters, staticProps]);

  return (
    <queryParameterContext.Provider
      value={{
        queryParameters,
        resetQueryParameters,
        setQueryParameters,
      }}
    >
      {children}
    </queryParameterContext.Provider>
  )
}

export function useQueryParameter<T extends QueryParameters>() {
  const value = useContext<QueryParameterContext<T>>(queryParameterContext);
  if (value === undefined) {
    throw new Error('useQueryParameterContext must be used within a QueryParameterContextProvider');
  }
  return value;
};
