import { Link, Text } from '@applyboard/crystal-ui'
import { useGetProgram } from '../../../hooks'

export function ReviewRequirementsButton({ programId }: { programId: string }) {
  const { program } = useGetProgram({ id: programId })
  const url = program?.attributes?.url ?? '';
  const sanitizedUrl = url.replace(/^https?:\/\//, '');

  return (
    <Text variant="labelL" overflow="nowrap">
      <Link
        href={!!program ? `https://${sanitizedUrl}` : '#'}
        mode="override_user_choice_and_open_in_new_tab"
        variant="standalone"
      >
        Review requirements
      </Link>
    </Text>
  )
}
