import { DescriptionList, Flex } from '@applyboard/crystal-ui'
import { ReactNode } from 'react'

type ApplicationDetailCardDescriptionItemProps = Readonly<{
  term: string
  details: string | ReactNode
}>

export function ApplicationDetailCardDescriptionItem(
  props: ApplicationDetailCardDescriptionItemProps,
) {
  return (
    <Flex direction={{ xs: 'row', lg: 'column' }} justify={{ xs: 'between', lg: 'start' }}>
      <DescriptionList.Term>{props.term}</DescriptionList.Term>
      <DescriptionList.Details>{props.details}</DescriptionList.Details>
    </Flex>
  )
}
