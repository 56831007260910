import { Heading } from "@applyboard/crystal-ui"

type ApplicationFormTitleProps = {
  icon: string
  title: string
  variant?: 'titleM' | 'titleS'
  level?: 3 | 2
}

export function ApplicationFormTitle({
  icon,
  title,
  variant = 'titleM',
  level = 2
}: Readonly<ApplicationFormTitleProps>) {
  return (
    <Heading level={level} variant={variant}>
      <span style={{ marginRight: 5 }} aria-hidden="true">{icon}</span>
      {title}
    </Heading>
  )
}
