import { Box, Card, Flex, Heading, Text } from '@applyboard/crystal-ui'

export function EmptyApplicationList() {
  return (
    <>
      <Box hideBelow="sm">
        <Card emphasis="elevated" aria-label="Empty application list">
          <EmptyApplication/>
        </Card>
      </Box>
      <Box hideAbove='sm'>
        <EmptyApplication/>
      </Box>
    </>
  )
}

function EmptyApplication() {
  return (
    <Flex direction="column" align="center" gap={8} py={{ xs: 20, sm: 36 }} px={6}>
      <img src="/application-clipboard-mobile.svg" width={160} alt="application clipboard" />
      <Flex direction="column" align="center" gap={2}>
        <Heading level={2} align="center">
          No active applications
        </Heading>
        <Text align="center">Once you start an application, you’ll be able to see it here.</Text>
      </Flex>
    </Flex>
  )
}
