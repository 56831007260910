import { format } from 'date-fns'
import { Tag } from '@applyboard/crystal-ui'

import { ApplicationDetailsIntent, ApplicationDetailsStatus } from '../types'

import { getIntent } from './utils'

type ApplicationDetailCardIssuedProps = Readonly<{
  intent: ApplicationDetailsIntent
  issuedOn: Date
  title?: string
}>

export function ApplicationDetailCardIssued(props: ApplicationDetailCardIssuedProps) {
  return (
    <Tag intent={getIntent(props.intent)}>
      {`${props.title === 'Declined' ? 'Declined' : 'Issued'} on ${format(props.issuedOn, 'MMM dd, yyy')}`}
    </Tag>
  )
}
