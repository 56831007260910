import { GetProgramIntakeResponse as GetProgramIntakeResponseBE } from '@backend/agents-ui-api/routes/intakes/{id}/GET/types'
import { ApiAgentRequest, request } from '../../utils'
import { useAuthQuery } from '../auth'

interface GetProgramIntakeProps {
  id?: string
}

export type GetProgramIntakeResponse = GetProgramIntakeResponseBE

export function useGetProgramIntake({ id }: GetProgramIntakeProps) {
  const { isLoading, data } = useAuthQuery(
    ['program-intake', id],
    async () => {
      return await request<GetProgramIntakeResponse>(
        new ApiAgentRequest(`/intakes/${id}`, {
          method: 'GET',
        }),
        {
          isExpectedResponse: (res): res is GetProgramIntakeResponse => res,
        },
      )
    },
    {
      enabled: !!id,
    },
  )

  return {
    isLoadingIntake: isLoading,
    intake: data?.data,
  }
}
