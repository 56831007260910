import styled from '@emotion/styled';
import { DatePicker as CyDatePicker, DatePickerProps } from '@applyboard/crystal-ui';

export function DatePicker(props: DatePickerProps) {
  return (
    <Styles>
      <CyDatePicker {...props} />
    </Styles>
  );
}

const Styles = styled.div({
  '& > div > div': {
    backgroundColor: '#f6f8fe',
    border: 0,
  },
});
