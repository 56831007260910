import { useMutation } from '@tanstack/react-query'
import { BaseRequest, request } from '../utils'

export interface S3FileUploadParams {
  file: File
  url: string
  fields: Record<string, string>
}

export function useS3Upload() {
  const { isPending, mutateAsync } = useMutation({
    mutationFn: async (params: S3FileUploadParams) => {
      const formData = new FormData()
      Object.keys(params.fields).forEach((key: string) => formData.append(key, params.fields[key]))
      formData.append('file', params.file)
      return await request(
        new BaseRequest(params.url, {
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          body: formData,
        }),
        {
          isExpectedResponse: (res): res is string => {
            return true // correct response is empty string
          },
        },
      )
    },
    retry: 3,
  })

  return {
    isLoadingS3Upload: isPending,
    s3UploadAsync: mutateAsync,
  }
}
