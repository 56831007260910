import { Tag } from '@applyboard/crystal-ui'
import { IntakeSearchResource } from '@backend/agents-ui-api/resources/intake-search/types'

type IntakeStatusProps = {
  status: IntakeSearchResource['attributes']['intake']['status']
  size: 'sm' | 'md' | 'lg'
}

export function IntakeStatus(props: IntakeStatusProps) {
  switch (props.status) {
    case 'OPEN':
      return <Tag size={props.size} intent="positive">Open</Tag>
    case 'CLOSED':
      return <Tag size={props.size} intent="secondary">Closed</Tag>
    case 'WILL_OPEN':
      return <Tag size={props.size} intent="magic">Opening Soon</Tag>
    default:
      return null
  }
}
