import React from 'react';
import { Table as CrystalTable, TableProps } from '@applyboard/crystal-ui';
import styled from '@emotion/styled';

const TableWrapper = styled.div({
  'td:last-child,th:last-child': {
    position: 'sticky',
    right: 0,
    backgroundColor: 'white'
  },
});

export function Table<T>(props: TableProps<T>) {
  return (
    <TableWrapper>
      <CrystalTable {...props} />
    </TableWrapper>
  );
}

Table.TextCell = CrystalTable.TextCell;
Table.Cell = CrystalTable.Cell;
Table.DateCell = CrystalTable.DateCell;
Table.CountryCell = CrystalTable.CountryCell;
