import styled from '@emotion/styled'
import { ApplicationFileLink } from './ApplicationFileLink'
import { useApplicationFormContext } from './ApplicationForm'
import { StudentApplication } from '../types'
import { IApplicationDocument } from '../ApplicationDocuments'

type ApplicationFileListProps = {
  application: StudentApplication
  disabled?: boolean
  documents?: Array<{
    id: string
    file: File
  }>
  files: IApplicationDocument[]
  showHistory?: boolean
  onRemove: (fileId: string) => void
}

export function ApplicationFileList(props: ApplicationFileListProps) {
  if (!props.files.length) return null

  return (
    <FileList>
      {props.files.map(file => {
        return (
          <ApplicationFileLink
            key={file.id}
            file={file}
            onDelete={!props.disabled ? props.onRemove : undefined}
            showHistory={props.disabled && props.showHistory}
          />
        )
      })}
    </FileList>
  )
}

const FileList = styled.ul({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  margin: '16px 0px 0px',
  padding: '0px',
  width: '100%',
})
