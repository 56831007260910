import { DescriptionList, Flex } from '@applyboard/crystal-ui'
import { formatAsDocumentDate } from '../../../utils/formatAsDocumentDate'
import { DocumentTags } from '../../../utils/enums'
import { parseISO } from 'date-fns'
import { LanguageExamHistoryRecord } from '../ApplicationDocuments'

export function LanguageExamHistoryEntryFields({ record }: { record: LanguageExamHistoryRecord }) {
  const referenceLabel = record.tags.includes(DocumentTags.LANGUAGE_TEST_IELTS)
    ? 'TRF Number'
    : record.tags.includes(DocumentTags.LANGUAGE_TEST_TOEFL)
      ? 'Appointment number'
      : record.tags.includes(DocumentTags.LANGUAGE_TEST_PTE)
        ? 'Score report code'
        : 'Reference number'

  return (
    <DescriptionList variant="free">
      <Flex direction="column" gap={12} pb={4}>
        <Flex gap={4} direction={{ xs: 'column', sm: 'row' }} wrap>
          <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
            <Flex direction="column">
              <DescriptionList.Term>Test Date</DescriptionList.Term>
              <DescriptionList.Details variant="bodyS">
                {record.context.testDate
                  ? formatAsDocumentDate(parseISO(record.context.testDate))
                  : '---'}
              </DescriptionList.Details>
            </Flex>
          </Flex.Item>
          <Flex.Item basis={{ xs: '100%', sm: 'calc(50% - 8px)' }}>
            <Flex direction="column">
              <DescriptionList.Term>{referenceLabel}</DescriptionList.Term>
              <DescriptionList.Details>
                {record.context.certificateNumber || '---'}
              </DescriptionList.Details>
            </Flex>
          </Flex.Item>
        </Flex>
        <Flex direction="column" gap={3}>
          <Flex gap={4} direction="row" wrap>
            <Flex.Item basis={'calc(50% - 8px)'}>
              <Flex direction="column">
                <DescriptionList.Term>Overall Score</DescriptionList.Term>
                <DescriptionList.Details>
                  {record.context.overallScore || '---'}
                </DescriptionList.Details>
              </Flex>
            </Flex.Item>
          </Flex>
          <Flex gap={4} direction="row" wrap>
            <Flex.Item basis={'calc(50% - 8px)'}>
              <Flex direction="column">
                <DescriptionList.Term>Listening</DescriptionList.Term>
                <DescriptionList.Details>
                  {record.context.subscores?.listening || '---'}
                </DescriptionList.Details>
              </Flex>
            </Flex.Item>
            <Flex.Item basis={'calc(50% - 8px)'}>
              <Flex direction="column">
                <DescriptionList.Term>Reading</DescriptionList.Term>
                <DescriptionList.Details>
                  {record.context.subscores?.reading || '---'}
                </DescriptionList.Details>
              </Flex>
            </Flex.Item>
          </Flex>
          <Flex gap={4} direction="row" wrap>
            <Flex.Item basis={'calc(50% - 8px)'}>
              <Flex direction="column">
                <DescriptionList.Term>Writing</DescriptionList.Term>
                <DescriptionList.Details>
                  {record.context.subscores?.writing || '---'}
                </DescriptionList.Details>
              </Flex>
            </Flex.Item>
            <Flex.Item basis={'calc(50% - 8px)'}>
              <Flex direction="column">
                <DescriptionList.Term>Speaking</DescriptionList.Term>
                <DescriptionList.Details>
                  {record.context.subscores?.speaking || '---'}
                </DescriptionList.Details>
              </Flex>
            </Flex.Item>
          </Flex>
        </Flex>
      </Flex>
    </DescriptionList>
  )
}
