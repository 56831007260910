import React from 'react';

const pagePropsContext = React.createContext<any | undefined>(undefined);

export interface PagePropsContextProps<T> {
  pageProps: T;
  children: React.ReactNode;
}

export function PagePropsContextProvider<T>({ pageProps, children }: PagePropsContextProps<T>) {
  const value = React.useMemo(
    () => { return pageProps },
    [pageProps]
  );
  return <pagePropsContext.Provider value={value}>{children}</pagePropsContext.Provider>;
}

export function usePageProps<T>() {
  const value = React.useContext<T>(pagePropsContext);
  if (value === undefined) {
    throw new Error('usePageProps must be used within a PagePropsContextProvider');
  }
  return value;
}
