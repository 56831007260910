import { Flex, GridAlpha, Heading, Link, Text, useToasts } from '@applyboard/crystal-ui'
import { ChevronLeftOutlineIcon } from '@applyboard/ui-icons'
import styled from '@emotion/styled'
import { useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  ApplicationForms,
  ApplicationHeader,
  ApplicationTimeline,
  BackToApplication,
} from '../../components/Application'
import ApplicationStateTag from '../../components/Application/ApplicationStateTag/ApplicationStateTag'
import { firstTabIncomplete } from '../../components/Application/ApplicationTimeline/utils'
import { Loading } from '../../components/Loading'
import { NavBars } from '../../components/NavBars'
import {
  GetProgramIntakeResponse,
  RawApplicationAggregationResponse,
  RawApplicationResponse,
  useCreateApplication,
  useGetApplication,
  useGetProgramIntake,
  useSubmitApplication,
  useUpdateApplication,
} from '../../hooks'
import { convertTimelessDateStrToLocalDate, GenericError, hasApplicationFee } from '../../utils'
import { CurrentProgramSummaryCard } from './CurrentProgramSummaryCard'
import { DropdownMenu } from './DropdownMenu'
import { ProgramFilters } from './ProgramFilters'
import { ProgramList } from './ProgramList'
import { SubmitApprovalDialog } from './SubmitApprovalDialog'
import { IntakeClosedDialog } from './IntakeClosedDialog'
import {
  ApplicationState,
  CampusInformation,
  PersonalInformation,
  ProgramInformation,
  ProgramIntakeTerm,
  RefinedVersionedApplicationResourceWithFiles,
} from 'applications-types-lib'
import { StudentApplication } from '../../components/Application/types'
import { SetAllFieldsOptional } from 'schools-domain-backend-utils'
import { useLocation } from 'react-router-dom'
import { PayAndSubmitApprovalDialog, SubmitConfirmation } from './SubmitConfirmation'

// These props is only meant to be used in tests, so that we can force the test to render the application form by specifying the current step
interface CreateApplicationPageProps {
  currentStepOverride?: number
  temporaryApplicationOverride?: RefinedVersionedApplicationResourceWithFiles
}

export function CreateApplicationPage({
  currentStepOverride,
  temporaryApplicationOverride,
}: CreateApplicationPageProps) {
  const toast = useToasts()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const location = useLocation()
  const currentApplicationId = searchParams.get('applicationId') || ''
  const paymentStatus = searchParams.get('status') || ''

  const { isLoadingApplication, application } = useGetApplication({
    id: currentApplicationId,
  })

  const [temporaryApplication, setTemporaryApplication] =
    useState<RefinedVersionedApplicationResourceWithFiles | null>()
  const { isCreatingApplication, createApplication } = useCreateApplication()
  const { isUpdatingApplication, updateApplication } = useUpdateApplication({
    id: currentApplicationId,
  })

  const viewApplicationPageRef = useRef<HTMLDivElement>(null)

  const { isSubmittingApplication, submitApplication } = useSubmitApplication({
    id: currentApplicationId,
  })

  const [showSubmitApprovalDialog, setShowSubmitApprovalDialog] = useState(false)
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false)

  const [currentStep, setCurrentStep] = useState(0)
  let selectedTab = currentStep - 1

  const isEditingProgramRef = useRef(false)

  useEffect(() => {
    if (currentStepOverride) {
      setCurrentStep(currentStepOverride)
    }
    if (temporaryApplicationOverride) {
      setTemporaryApplication(
        temporaryApplicationOverride as RefinedVersionedApplicationResourceWithFiles,
      )
    }
  }, [currentStepOverride, temporaryApplicationOverride])

  useEffect(() => {
    if (application && currentStep === 0 && !isEditingProgramRef.current) {
      setCurrentStep(1)
    }
    if (isEditingProgramRef.current) {
      isEditingProgramRef.current = false
    }
  }, [application, currentStep])

  useEffect(() => {
    if (paymentStatus === 'error' || paymentStatus === 'cancelled') {
      toast.negative(
        new Error(`Your payment could not be processed for application submission. Please make sure you
           have sufficient funds and your payment details are correct or try an alternative
           payment method.`),
      )
    } else if (paymentStatus === 'success') {
      navigate(`/`, { replace: true })
    }
  }, [paymentStatus])

  const { intake, isLoadingIntake } = useGetProgramIntake({
    id: application?.attributes?.programSelected?.programIntakeId,
  })

  const isIntakeClosed = useMemo(() => {
    if (isLoadingIntake || !intake) return false
    return !intake?.attributes?.isAvailable
  }, [intake])

  const [openIntakeClosedDialog, setOpenIntakeClosedDialog] = useState(isIntakeClosed)

  useEffect(() => {
    setOpenIntakeClosedDialog(isIntakeClosed)
  }, [isIntakeClosed])

  const queryParam = searchParams.get('pageNumber')
  const applicationId = searchParams.get('applicationId')

  useEffect(() => {
    if (location.pathname === '/applications/new' && !!queryParam && !applicationId) {
      setCurrentStep(0)
      setTemporaryApplication(null)
    }
  }, [location, queryParam, applicationId, application, setSearchParams])

  useEffect(() => {
    if (application && currentStep < 8) {
      if (application.attributes?.applicationState !== ApplicationState.DRAFT) {
        navigate(`/applications/${application.id}`, { replace: true })
        toast.warning(
          `The student's application has already been submitted. The page has been refreshed to reflect the current status.`,
        )
      } else {
        navigate(`/applications/new?applicationId=${application.id}`, { replace: true })
      }
    }

    if (currentStep > 0) {
      window.scrollTo(0, 0)
    }
  }, [application, navigate, currentStep, toast])

  const paySubmitButtonRef = useRef<HTMLButtonElement>(null)
  useEffect(() => {
    if (showSubmitApprovalDialog === false && paySubmitButtonRef.current) {
      paySubmitButtonRef.current.focus()
    }
  }, [showSubmitApprovalDialog])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isLoadingApplication && viewApplicationPageRef.current) {
        viewApplicationPageRef.current.focus()
      }
    }, 500)
    return () => clearTimeout(timer)
  }, [isLoadingApplication])

  const handleUpdateApplication = (intakeId: string | null) => {
    if (!intakeId) {
      toast.negative(new Error('Failed to update program selection.'))
      return
    }
    updateApplication(
      {
        attributes: {
          programSelected: {
            priority: 0,
            programIntakeId: intakeId,
          },
        },
      },
      {
        onSuccess: response => {
          queryClient.invalidateQueries({ queryKey: ['intake-search'] })
          setSearchParams({ applicationId: response.data.id })
          setCurrentStep(step => step + 1)

          queryClient.setQueryData(
            ['applications', response.data.id],
            (oldData: RawApplicationAggregationResponse): RawApplicationAggregationResponse => {
              return {
                data: {
                  ...oldData.data,
                  attributes: {
                    ...oldData.data.attributes,
                    application: response.data,
                  },
                },
              }
            },
          )

          toast.positive(
            `The student's application has been successfully updated! Please review the requirements to confirm that all required information has been submitted.`,
          )
        },
        onError: err => {
          if (err instanceof Error) {
            toast.negative(new Error(err.message))
            isEditingProgramRef.current = false
          }
        },
      },
    )
  }

  const handleCreateApplication = (
    intakeId: string,
    personalInformation: SetAllFieldsOptional<PersonalInformation>,
  ) => {
    createApplication(
      {
        intakeId,
        personalInformation,
      },
      {
        onSuccess: response => {
          setSearchParams({ applicationId: response.data.id })
          setCurrentStep(1)
        },
        onError: err => {
          if (err instanceof Error) {
            toast.negative(new Error(err.message))
          }
        },
      },
    )
  }

  const handleSubmitApplication = (
    successCallback: (response: RawApplicationResponse) => void,
    errorCallback: (err: GenericError) => void,
  ) => {
    submitApplication(undefined, {
      onSuccess: response => {
        successCallback(response)
      },
      onError: err => {
        if (err instanceof Error) {
          errorCallback(err)
        }
      },
    })
  }

  const handleSubmitConfirmation = async () => {
    handleSubmitApplication(
      (response: RawApplicationResponse) => {
        queryClient.setQueryData(
          ['applications', response.data.id],
          (oldData: RawApplicationAggregationResponse): RawApplicationAggregationResponse => {
            return {
              data: {
                ...oldData.data,
                attributes: {
                  ...oldData.data.attributes,
                  application: response.data,
                },
              },
            }
          },
        )

        setShowSubmitApprovalDialog(false)
        setShowSubmitConfirmation(true)
      },
      (err: GenericError) => {
        setShowSubmitApprovalDialog(false)
        setShowSubmitConfirmation(false)
        toast.negative(err)
      },
    )
  }

  const handleSubmitApplicationTrigger = async () => {
    await queryClient.invalidateQueries({
      queryKey: ['program-intake', intake?.id],
    })
    const updatedIntake = queryClient.getQueryData([
      'program-intake',
      intake?.id,
    ]) as GetProgramIntakeResponse

    if (!updatedIntake?.data?.attributes?.isAvailable) {
      setOpenIntakeClosedDialog(true)
    } else {
      setShowSubmitApprovalDialog(true)
    }

    setShowSubmitApprovalDialog(true)
  }

  if (currentApplicationId && isLoadingApplication) {
    return (
      <NavBars>
        <Loading />
      </NavBars>
    )
  }

  if ((!application && !temporaryApplication) || currentStep === 0) {
    return (
      <NavBars largePage>
        <Flex gap={6} direction="column">
          <Text>
            <Link
              href={application ? `/applications/${currentApplicationId}` : '/'}
              leadIcon={ChevronLeftOutlineIcon}
              variant="standalone"
            >
              {application ? 'Back to current application' : 'Back to My Applications'}
            </Link>
          </Text>
          {application ? (
            <CurrentProgramSummaryCard
              applicationSummary={{
                programName: application.attributes?.programSelected?.program?.name ?? '',
                campusName: application?.attributes?.programSelected?.campus?.name ?? '',
                intakeTermDate: application?.attributes?.programSelected?.programIntakeTerm
                  ?.startDate
                  ? format(
                    convertTimelessDateStrToLocalDate(
                      application?.attributes?.programSelected?.programIntakeTerm?.startDate,
                    ),
                    'MMM yyyy',
                  )
                  : '',
              }}
            />
          ) : null}
          <Heading level={1} variant="headlineL">
            Program Selection
          </Heading>

          <ProgramFilters />
          <ProgramList
            loading={isCreatingApplication || isUpdatingApplication}
            onSubmit={intake => {
              if (!application) {
                setTemporaryApplication({
                  attributes: {
                    applicationState: ApplicationState.DRAFT,
                    programSelected: {
                      priority: 0,
                      programIntakeId: intake?.id,
                      program: intake?.attributes?.program as ProgramInformation,
                      programIntakeTerm: {
                        id: intake?.attributes?.intakeTerm?.id,
                        name: intake?.attributes?.intakeTerm?.name,
                        startDate: intake?.attributes?.intakeTerm?.startsOn,
                      } as ProgramIntakeTerm,
                      campus: intake?.attributes?.campus as CampusInformation,
                    },
                    applicationFee: {
                      amount: intake?.attributes?.program?.applicationFee,
                    },
                  },
                } as RefinedVersionedApplicationResourceWithFiles)

                setCurrentStep(1)
                navigate(`/applications/new`, { replace: true })
              } else {
                handleUpdateApplication(intake.id)
              }
            }}
            currentApplication={application?.attributes}
          />
        </Flex>
      </NavBars>
    )
  }

  if (currentStep < 2) {
    selectedTab = firstTabIncomplete(application || (temporaryApplication as StudentApplication))
  }

  return (
    <ApplicationWrapper tabIndex={-1} ref={viewApplicationPageRef}>
      <NavBars largePage>
        <BackToApplication />
        <Flex direction="column" gap={10}>
          <Flex wrap={false} align="center" justify="between" gap={4}>
            <Flex
              hideBelow={selectedTab === 7 && showSubmitApprovalDialog ? 'sm' : undefined}
              align={{ xs: 'start', md: 'center' }}
              gap={4}
              direction={{ xs: 'column', md: 'row' }}
              py={4}
            >
              <Heading level={1} variant="headlineL">
                New Application
              </Heading>
              {application ? (
                <ApplicationStateTag state={application.attributes?.applicationState} hasOpenDocumentRequests={application.attributes?.hasOpenDocumentRequests} />
              ) : null}
            </Flex>
            {application ? (
              <DropdownMenu
                application={application}
                onEditProgram={() => {
                  isEditingProgramRef.current = true
                  setCurrentStep(0)
                }}
              />
            ) : null}
          </Flex>
          <GridAlpha
            columnGap={12}
            rowGap={5}
            columns={{
              xs: '1fr',
              md: '336px 1fr',
            }}
            areas={{
              xs: ['aside', 'form'],
              md: ['aside form'],
            }}
          >
            <GridAlpha.Item areaName="aside">
              <Flex direction="column" gap={4}>
                <Flex
                  hideBelow={selectedTab === 7 && showSubmitApprovalDialog ? 'sm' : undefined}
                  direction="column"
                >
                  <ApplicationHeader
                    application={application || (temporaryApplication as StudentApplication)}
                  />
                </Flex>
                <ApplicationTimeline
                  selectedTab={selectedTab}
                  setSelectedTab={tab => {
                    setOpenIntakeClosedDialog(!intake?.attributes?.isAvailable)
                    setCurrentStep(tab + 1)
                  }}
                  application={application || (temporaryApplication as StudentApplication)}
                />
              </Flex>
            </GridAlpha.Item>
            <GridAlpha.Item areaName="form">
              <Flex
                grow={1}
                hideBelow={showSubmitApprovalDialog || showSubmitConfirmation ? 'sm' : undefined}
              >
                <ApplicationForms
                  selectedTab={selectedTab}
                  application={application || (temporaryApplication as StudentApplication)}
                  focusRef={paySubmitButtonRef}
                  onSuccess={response => {
                    if (response && selectedTab <= 6) {
                      queryClient.invalidateQueries({
                        queryKey: ['applications', response.data.id],
                      })
                      setCurrentStep(selectedTab + 2)
                    }
                  }}
                  onCreateApplication={(
                    programIntakeId: string,
                    personalInformation: SetAllFieldsOptional<PersonalInformation>,
                  ) => {
                    handleCreateApplication(programIntakeId, personalInformation)
                  }}
                  // onError={(err: Error) => {
                  //   toast.negative(err)
                  //   queryClient.invalidateQueries({ queryKey: ['applications', application.id] })
                  // }}
                  submitApplication={handleSubmitApplicationTrigger}
                  currentIntakeAvailable={intake?.attributes.isAvailable}
                  onIntakeUnavailable={() => {
                    setOpenIntakeClosedDialog(!intake?.attributes?.isAvailable)
                  }}
                  isCreatingApplication={isCreatingApplication}
                />
              </Flex>

              {hasApplicationFee(application) ? (
                <PayAndSubmitApprovalDialog
                  showSubmitApprovalDialog={showSubmitApprovalDialog}
                  setShowSubmitApprovalDialog={setShowSubmitApprovalDialog}
                  application={application as StudentApplication}
                />
              ) : (
                <SubmitApprovalDialog
                  showSubmitApprovalDialog={showSubmitApprovalDialog}
                  setShowSubmitApprovalDialog={setShowSubmitApprovalDialog}
                  onSubmitConfirmation={handleSubmitConfirmation}
                  isLoading={isSubmittingApplication}
                />
              )}

              <SubmitConfirmation
                showSubmitConfirmation={showSubmitConfirmation}
                setShowSubmitConfirmation={setShowSubmitConfirmation}
                onClose={() => {
                  navigate(`/applications/${application?.id}`)
                }}
              />
            </GridAlpha.Item>
          </GridAlpha>
        </Flex>
      </NavBars>
      <IntakeClosedDialog
        open={openIntakeClosedDialog}
        onOpenChange={setOpenIntakeClosedDialog}
        onEditProgram={() => {
          isEditingProgramRef.current = true
          setCurrentStep(0)
        }}
      />
    </ApplicationWrapper>
  )
}

const ApplicationWrapper = styled.div({
  outline: 'none',
})
