import { Card, Flex, Tag, Text } from '@applyboard/crystal-ui'

type CurrentProgramSummaryCardProps = {
  applicationSummary: {
    programName: string
    campusName: string
    intakeTermDate: string
  }
}
export function CurrentProgramSummaryCard(props: CurrentProgramSummaryCardProps) {
  return (
    <Card variant="highlighted">
      <Flex direction="column" gap={2}>
        <Text variant="titleS">Your current selection:</Text>
        <Flex direction="row" gap={2}>
          <Text>{props.applicationSummary.programName}</Text>
          <Tag intent="primary">{props.applicationSummary.campusName}</Tag>
          <Tag intent="secondary">{props.applicationSummary.intakeTermDate}</Tag>
        </Flex>
      </Flex>
    </Card>
  )
}
