import { Box, Flex, Link, Pagination, Table, Tag, Text } from "@applyboard/crystal-ui";
import { IntakeSearchResource, PaginationCollectionResponse } from "@backend/agents-ui-api/resources/intake-search/types";
import { format } from "date-fns";
import { convertTimelessDateStrToLocalDate } from "../../../../utils";
import { DeliveryMethod } from "../DeliveryMethod";
import { IntakeStatus } from "../IntakeStatus";
import { ProgramLength } from "../ProgramLength";
import { RefinedVersionedApplicationResourceWithFiles } from "applications-types-lib";
import { ProgramCardButton } from "../ProgramCardButton";

interface ProgramListDesktopProps {
  onSubmit: (intake: IntakeSearchResource) => void;
  setSelectedIntakeId: (intakeId: string) => void;
  updateFilters: (filters: Record<string, string>) => void;
  intakeSearch: IntakeSearchResource[];
  intakeSearchMeta: PaginationCollectionResponse<RefinedVersionedApplicationResourceWithFiles>['meta'] | undefined;
  loading: boolean;
  selectedIntakeId: string | undefined;
  currentApplication: RefinedVersionedApplicationResourceWithFiles['attributes'];
}

export function ProgramListDesktop(props: ProgramListDesktopProps) {

  function formatURL(url: string) {
    if (!url.startsWith('https://')) {
      return 'https://' + url;
    }
    return url;
  }

  return (
    <Flex direction="column" gap={8}>
      <Table
        columns={[
          {
            accessorKey: 'programName',
            cell: (intake: IntakeSearchResource) => (
              <Flex direction='column' pt={2} pb={5} px={4}>
                <Text id={intake.id}>
                  {intake.attributes.program.url ? (
                    <Link
                      href={formatURL(intake.attributes.program.url)}
                      mode="override_user_choice_and_open_in_new_tab"
                      variant="standalone"
                    >
                      {intake.attributes.program.name}
                    </Link>
                  ) : (
                    intake.attributes.program.name
                  )}
                </Text>
                <Flex gap={2} wrap>
                  {intake.attributes.programLevel?.name ? (
                    <Tag intent="secondary">{intake.attributes.programLevel.name}</Tag>
                  ) : null}
                  <ProgramLength
                    length={intake.attributes.program.length}
                    unit={intake.attributes.program.lengthUnit as 'years' | 'months' | 'weeks'}
                  />
                  <DeliveryMethod deliveryMethod={intake.attributes.program.deliveryMethod} />
                </Flex>
              </Flex>
            ),
            header: 'Program name',
          },
          {
            accessorKey: 'campus',
            header: 'Campus',
            cell: (intake: IntakeSearchResource) => (
              <Box px={4}>
                <Text>{intake.attributes.campus.name}</Text>
              </Box>
            ),
          },
          {
            accessorKey: 'start',
            header: 'Start',
            cell: (intake: IntakeSearchResource) => (
              <Box px={4}>
                <Text>
                  {format(
                    convertTimelessDateStrToLocalDate(intake.attributes.intakeTerm.startsOn),
                    'MMM yyyy',
                  )}
                </Text>
              </Box>
            ),
          },
          {
            accessorKey: 'status',
            header: 'Status',
            cell: (intake: IntakeSearchResource) => (
              <Box px={3}>
                <IntakeStatus size="md" status={intake.attributes.intake.status} />
              </Box>
            ),
          },
          {
            accessorKey: 'action',
            header: 'Action',
            cell: (intake: IntakeSearchResource) => (
              <Box px={2}>
                <ProgramCardButton
                  isDesktop={true}
                  intakeSearch={intake}
                  disabled={props.loading}
                  loading={props.selectedIntakeId !== intake.id && props.loading}
                  onClick={() => {
                    props.setSelectedIntakeId(intake.id)
                    props.onSubmit(intake)
                  }}
                  currentApplication={props.currentApplication}
                />
              </Box>
            ),
          }
        ]}
        data={props.intakeSearch}
      />
      {props.intakeSearchMeta?.pagination ? (
        <Pagination
          currentPage={props.intakeSearchMeta.pagination.currentPage}
          numItems={props.intakeSearchMeta.pagination.total}
          onPageChange={(pageNumber: number) => {
            props.updateFilters({
              pageNumber: pageNumber.toString(),
            })
          }}
          onPageSizeChange={(pageSize: number) => {
            props.updateFilters({
              pageNumber: '1',
              perPage: pageSize.toString(),
            })
          }}
          pageSize={props.intakeSearchMeta.pagination.perPage}
          pageSizeOptions={[10, 20, 30]}
          variant="items"
        />
      ) : null}
    </Flex>
  )
}