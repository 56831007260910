import { useMemo } from "react";
import { useQueryParameter } from "../../context";
import { useGetApplicationsQueryDefinitions } from "./useGetApplicationsQueryDefinitions";
import { useGetApplications } from "./useGetApplications";
import { GetApplicationsQuerys, GetApplicationSearchQuery } from "./types";

export function useGetApplicationsQuery(options: { enabled: boolean }) {
  const { queryParameters } = useQueryParameter<GetApplicationsQuerys>();
  const { definitionsAsArray } = useGetApplicationsQueryDefinitions();

  const searchQuery: GetApplicationSearchQuery = useMemo(() => {
    return definitionsAsArray.reduce((acc, definition) => {
      const query = queryParameters[definition.key]
      //@ts-ignore
      const result = definition.toSearchQuery(definition.key === 'submissionDate' ? query : decodeURIComponent(query))
      Object.assign(acc, result)
      return acc
    }, {} as GetApplicationSearchQuery)
  }, [definitionsAsArray, queryParameters])

  return useGetApplications(searchQuery, options);
}
