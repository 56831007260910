import { VersionedApplicationSanitizedWithFiles } from "@backend/sanitize/application";
import { getFilesOfType } from "../ApplicationForms/utils";
import { DocumentTags } from "../../../utils";
import { FileData } from "applications-types-lib";

export type ApplicationDetailsFile = {
  name: string;
  url: string;
  hash: string;
  uploadedAt: string;
}

export function getFile(currentKey: string, application: VersionedApplicationSanitizedWithFiles): ApplicationDetailsFile {
  const metaFiles = application?.meta?.files
  const files = getFilesOfType(
    [
      DocumentTags.PRE_OFFER_LETTER, 
      DocumentTags.FINAL_OFFER_LETTER,
      DocumentTags.WAITLIST_LETTER,
      DocumentTags.DECLINED_LETTER, 
      DocumentTags.SCHOOL_ADDITIONAL_DOCUMENT
    ],
    application?.attributes?.files as FileData,
  )

  const fileKey: string =
    Object.keys(files).find(
      key => key === currentKey,
    ) ?? ''

  return {
    name: files[fileKey]?.fileName || '-',
    url: metaFiles[fileKey]?.download?.url || '#',
    hash: files[fileKey]?.hash || '',
    uploadedAt: files[fileKey]?.uploadedAt || new Date().toISOString()
  }
}