import { PropsWithChildren } from 'react'
import styled, { CSSObject } from '@emotion/styled'
import { Card, Flex } from '@applyboard/crystal-ui'
import { up } from '../../utils'
import { useGetSchool } from '../../hooks'
import { Navigate } from 'react-router-dom'
import { Loading } from '../Loading'

export function OnBoardingBox(props: PropsWithChildren) {
  const { isLoadingSchool, school } = useGetSchool()

  if (!isLoadingSchool && !school) {
    return <Navigate to="/not-found" />
  }

  return (
    <PageContainer>
      <Flex align="center" grow={1} gap={20} py={{ xs: 12, sm: 20 }} direction="column">
        {isLoadingSchool ? (
          <Flex direction="column" justify="between">
            <Loading />
          </Flex>
        ) : (
          <>
            <Flex hideBelow="sm">
              <img alt={school?.attributes.name} src={school?.attributes.logo} width={304} />
            </Flex>
            <Flex hideAbove="sm">
              <img alt={school?.attributes.name} src={school?.attributes.logo} width={138} />
            </Flex>
            <Box>
              <Card>{props.children}</Card>
            </Box>
          </>
        )}
      </Flex>
    </PageContainer>
  )
}

const PageContainer = styled.div((): CSSObject => ({
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  width: '100vw',
  minHeight: '100vh',
  display: 'flex',
  position: 'relative',
  paddingLeft: 55,
  paddingRight: 55,
  [up('sm')]: {
    backgroundImage: `linear-gradient(to right, rgba(0, 54, 138, .5), rgba(0, 54, 138, .5)), url("/signup-bg.svg")`,
    paddingLeft: 15,
    paddingRight: 15,
  },
}))

const Box = styled.div(() => ({
  width: '100%',
  maxWidth: 600,
  '> section': {
    padding: '24px 8px',
  },
}))
