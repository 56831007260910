import { Text } from '@applyboard/crystal-ui'
import { GetIntakeSearchResponse } from '@backend/agents-ui-api/routes/intake-search/GET/types'

export function PaginationDetails({ pagination }: GetIntakeSearchResponse['meta']) {
  const initialValue =
    pagination.total > 0 ? (pagination.currentPage - 1) * pagination.perPage + 1 : 0
  const lastValue =
    pagination.currentPage * pagination.perPage >= pagination.total
      ? pagination.total
      : pagination.currentPage * pagination.perPage

  return <Text>{`${initialValue}-${lastValue} of ${pagination.total} rows`}</Text>
}
