import { useAuthQuery } from '../auth'
import { ApiAgentRequest, request } from '../../utils'
import { GetCampusesResponse } from '../../../../backend/agents-ui-api/routes/campuses/GET/types'

export function useGetCampuses() {
  const { isLoading, isFetching, data } = useAuthQuery(
    ['campuses'],
    async () => {
      return await request<GetCampusesResponse>(
        new ApiAgentRequest('/campuses', {
          method: 'GET',
        }),
        {
          isExpectedResponse: (res): res is GetCampusesResponse => res,
        },
      )
    },
    {
      refetchOnWindowFocus: false,
    },
  )

  return {
    isLoadingCampuses: isLoading,
    isFetchingCampuses: isFetching,
    campuses: data?.data || [],
  }
}
