import { Button, Dialog, Flex, Heading, Text } from '@applyboard/crystal-ui'
import styled from '@emotion/styled'
import { Dispatch, SetStateAction, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useScreenResize } from '../../../hooks'
import { breakpoints } from '../../../utils'

export function SubmitConfirmation(props: {
  showSubmitConfirmation: boolean
  setShowSubmitConfirmation: Dispatch<SetStateAction<boolean>>
  onClose?: () => void
}) {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(window.innerWidth >= breakpoints.sm)

  useScreenResize(() => {
    setShowModal(window.innerWidth >= breakpoints.sm)
  })

  const handleClose = (open: boolean) => {
    props.setShowSubmitConfirmation(open);

    if (!open && props.onClose) {
      props.onClose();
    }
  };

  if (!props.showSubmitConfirmation) return null

  return (
    <>
      <Flex hideAbove="sm" direction="column" gap={10} grow={1}>
        <Heading level={1} variant="headlineL" align="center">
          Application Submitted
        </Heading>
        <Flex direction="column" gap={4} align="center">
          <Image alt="congratulations" src="/application_success.svg" />
          <Text>Your application was submitted successfully. </Text>
        </Flex>
        <Flex pt={1} direction="column" gap={4}>
          <Button
            onClick={() => {
              props.setShowSubmitConfirmation(false)
              navigate('/')
            }}
            width="fill"
            intent="primary"
          >
            View all applications 
          </Button>
        </Flex>
      </Flex>

      <Dialog
        onOpenChange={(open) => {
          handleClose(open);
        }}
        open={props.showSubmitConfirmation && showModal}
      >
        <Dialog.Heading>Application Submitted</Dialog.Heading>
        <Dialog.Content>
          <Flex direction="column" gap={6} align="center">
            <Image alt="congratulations" src="/application_success.svg" />
            <Text>Your application was submitted successfully. </Text>
          </Flex>
        </Dialog.Content>
        <Dialog.Actions>
          <Button
            onClick={() => {
              props.setShowSubmitConfirmation(false)
              navigate('/')
            }}
            width="fill"
            intent="primary"
          >
            View all applications 
          </Button>
        </Dialog.Actions>
      </Dialog>
    </>
  )
}

const Image = styled.img({
  width: '100%',
  maxWidth: 300,
})
