import { parseISO, format } from 'date-fns'
import { DocumentTags } from '../../../utils/enums'
import { IApplicationDocument } from './types'

export class ApplicationDocument implements IApplicationDocument {
  id: string
  uploadDate: Date
  tags: DocumentTags[]
  url?: string
  name: string
  section: string
  history: IApplicationDocument[]

  constructor({
    id,
    uploadDate,
    tags,
    url,
    name,
    section,
    history,
  }: {
    id: string
    uploadDate?: string
    tags?: DocumentTags[]
    url?: string
    name?: string
    section?: string
    history?: IApplicationDocument[]
  }) {
    this.id = id
    this.uploadDate = parseISO(uploadDate || '2044-01-01')
    this.tags = tags || []
    this.url = url
    this.name = name || 'Unknown file'
    this.section = section || 'unknown'
    this.history = history || []
  }

  isBlankDatePlaceholder() {
    return format(this.uploadDate, 'dd MMM yyyy') === format(parseISO('2044-01-01'), 'dd MMM yyyy')
  }
}
