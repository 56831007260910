export function formatDate(
  date: Date | undefined | null,
  locals: Intl.LocalesArgument = 'en-CA',
  options: Intl.DateTimeFormatOptions = {}
): string {
  if (!date) {
    return '';
  }

  const formattedDate = date.toLocaleDateString(locals, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    ...options,
  });

  return formattedDate;
}

export function formatNonTimezoneDate(dateStr: Date | undefined | null): string {
  if (!dateStr) {
    return '';
  }
  const formattedDate = Intl.DateTimeFormat('en-CA', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  }).format(new Date(dateStr));

  return formattedDate;
}
