import jwt_decode from 'jwt-decode'
import { AuthStorage } from '../../utils'

// TODO: Work on it on Auth ticket
type AgentPayload = any

export function useAuthToken() {
  function getAccessTokenPayload(): AgentPayload | null {
    const accessToken = AuthStorage.getAccessToken()

    if (!accessToken) return null
    try {
      return jwt_decode(accessToken) as AgentPayload
    } catch (e) {
      return null
    }
  }

  function isActiveSession(): boolean {
    const tokenPayload = getAccessTokenPayload()
    if (!tokenPayload?.exp) return false
    return Date.now() < tokenPayload.exp * 1000
  }

  function isRefreshableSession(): boolean {
    return !!AuthStorage.getRefreshToken()
  }

  return {
    isRefreshableSession,
    getAccessTokenPayload,
    isActiveSession,
  }
}
