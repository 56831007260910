import { useEffect, useState } from 'react';
import { MultiCombobox } from '../../..//components/uie-fix/MultiCombobox';
import { Loading } from '../../../components/Loading';
import { useDebouncedState , useFilterField, useGetPrograms } from '../../../hooks';

const API_REQUEST_DELAY = 300;

export function ProgramFilter() {
  const programFilter = useFilterField('programId');
  const [debouncedSearchInput] = useDebouncedState('', API_REQUEST_DELAY);
  const { programs, isFetchingPrograms } = useGetPrograms({'filter[q]': debouncedSearchInput, 'page[size]': 10000, 'page[number]': 1 });
  const [ selectedItems, setSelectedItems ] = useState<string[]>(programFilter.value);

  useEffect(() => {
    if (!isFetchingPrograms) {
      setSelectedItems(programFilter.value);
    }
  }, [isFetchingPrograms, programFilter.value]);

  if (isFetchingPrograms && !programs) {
    return <Loading />
  }

  return (
    <MultiCombobox
      value={selectedItems}
      aria-label="Program Name"
      placeholder="Program Name"
      onChange={(selectedItems) => {
        programFilter.setValue(selectedItems.filter((s) => !!s.trim()))}
      }
      placeholderVariant="indicator"
    >
      {(programs && !isFetchingPrograms) && programs?.map((program) => <MultiCombobox.Option label={program.attributes.name} value={program.id} key={program.id} />)}
      
    </MultiCombobox>
  );
};
