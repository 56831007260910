import { useEffect } from 'react';
import { TextInput } from '@applyboard/crystal-ui';
import { useDebouncedState, useFilterField } from '../../../hooks';

const API_REQUEST_DELAY = 500;

export function QueryFilter() {
  const { setValue, value } = useFilterField('searchQuery');
  const [currentValue, _, setSearchInput] = useDebouncedState(value, {
    delay: API_REQUEST_DELAY,
    onChange: () => {
      setValue(currentValue)
    }
  });

  useEffect(() => {
    setSearchInput(value)
  }, [setSearchInput, value])

  return (
    <TextInput
      helpText=""
      aria-label="Applications Search"
      intent="secondary"
      onChange={setSearchInput}
      placeholder="Search through applications..."
      size="md"
      type="search"
      value={currentValue}
    />
  );
}
