import { FileData } from 'applications-types-lib'
import { DocumentTags } from '../../../utils/enums'

type FilesOfTypeOptions = {
  activeFiles?: boolean
}

export function getFilesOfType(fileTypes: DocumentTags[], fileData?: FileData, options?: FilesOfTypeOptions): FileData {
  return Object.entries(fileData || {}).reduce((acc, [id, fileData]) => {
    if (!fileData) return acc
    if (fileTypes.includes(fileData.type as unknown as DocumentTags)) {
      if (options?.activeFiles && !fileData.activeRecord) {
        return acc
      }
      acc[id] = fileData
    }
    return acc
  }, {} as FileData)
}
