import { useState } from 'react';
import { Button, Drawer, Flex } from "@applyboard/crystal-ui";
import { FilterOutlineIcon } from '@applyboard/ui-icons';
import { QueryFilter } from "./QueryFilter";
import { NationalityFilter } from "./NationalityFilter";
import { ProgramFilter } from "./ProgramFilter";
import { IntakeTermFilter } from "./IntakeTermFilter";
import { StatusFilter } from "./StatusFilter";
import { SubmissionDateFilter } from "./SubmissionDateFilter";
import { useQueryParameter } from '../../../context';
import { GetApplicationsQuerys } from '../../../hooks';
import { FilterSummary } from './FilterSummary';

export function MobileFilters() {
  return (
    <Flex direction={'column'} gap={2}>
      <Flex direction={'row'} gap={3}>
        <Flex.Item grow={1}>
          <QueryFilter />
        </Flex.Item>
        <Flex.Item>
          <FilterDrawer />
        </Flex.Item>
      </Flex>
      <FilterSummary/>
    </Flex>
  );
}

export function FilterDrawer() {
  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const { resetQueryParameters } = useQueryParameter<GetApplicationsQuerys>()

  const openChangeDialog = (open: boolean) => {
    setFilterDrawerOpen(open);
  };

  return (
    <Drawer open={isFilterDrawerOpen} placement="right" onOpenChange={openChangeDialog}>
      <Drawer.Trigger>
        <Button aria-label='Filter Applications' leadIcon={FilterOutlineIcon} intent="secondary" emphasis='outlined'/>
      </Drawer.Trigger>
      <Drawer.Header>Filter applications</Drawer.Header>
      <Flex direction="column" gap={4}>
        <NationalityFilter />
        <ProgramFilter />
        <IntakeTermFilter />
        <StatusFilter />
        <SubmissionDateFilter />
      </Flex>
      <Drawer.Footer>
        <Flex gap={2}>
          <Button
            intent="secondary"
            variant="highlighted"
            width="fill"
            onClick={() => {
              resetQueryParameters();
            }}
          >
            Clear all
          </Button>
          <Button
            intent="primary"
            width="fill"
            onClick={() => {
              setFilterDrawerOpen(false);
            }}
          >
            Apply
          </Button>
        </Flex>
      </Drawer.Footer>
    </Drawer>
  );
};
