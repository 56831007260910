import { Breadcrumb, Flex } from '@applyboard/crystal-ui'

type BackToApplicationProps = {
  label?: string
}

export function BackToApplication({ label = 'New Application' }: BackToApplicationProps) {
  return (
    <Flex pb={{ xs: 6, sm: 6 }}>
      <Breadcrumb
        items={[
          {
            href: '/',
            label: 'My Applications',
          },
          {
            label,
            href: '/#',
          },
        ]}
      />
    </Flex>
  )
}
