import { ButtonLink, Flex, Heading } from "@applyboard/crystal-ui";
import {
  ApplicationsPageProps,
  GetApplicationsQuerys,
  useGetApplicationsPageProps,
  useGetApplicationsQueryDefinitions,
  useMediaQuery,
} from "../../hooks";
import { NavBars } from '../../components/NavBars'
import { ApplicationsList } from "./ApplicationsList/ApplicationsList";
import { PagePropsContextProvider } from "../../context/PagePropsContext";
import { QueryParameterContextProvider } from "../../context";
import { ApplicationFilters } from "./ApplicationsFilters";
import { Loading } from "../../components/Loading";
import { PlusIcon } from "@applyboard/ui-icons";

export function ApplicationsListPage() {
  const { isMobile } = useMediaQuery()

  return (
    <NavBars largePage>
      <ApplicationListPageContext>
        <Flex gap={3} direction="column">
          <Flex justify="between" align="center" direction="row">
            <Heading level={1} variant="headlineL">
              Applications
            </Heading>
            {!isMobile ? <ButtonLink
              aria-label={`New Application`}
              emphasis="contained"
              href={`/applications/new`}
              intent="primary"
              leadIcon={() => {
                return <PlusIcon variant="outline"/>;
              }}
              size="md"
            >
              Create new application
            </ButtonLink> : null}
          </Flex>
          <ApplicationFilters />
          <ApplicationsList />
            {isMobile ?
              <Flex justify="center">
                <ButtonLink
                aria-label={`New Application`}
                emphasis="contained"
                href={`/applications/new`}
                intent="primary"
                leadIcon={() => {
                  return <PlusIcon variant="outline"/>;
                }}
                size="md"
                width="fill"
                >
                  Create new application
                </ButtonLink>
              </Flex> : null
            }
        </Flex>
      </ApplicationListPageContext>
    </NavBars>
  )
}

type ApplicationListPageContextProps = {
  readonly children: React.ReactNode
}

function ApplicationListPageContext({ children }: ApplicationListPageContextProps) {
  const { pageProps, isFetchingApplications, isLoadingApplications } = useGetApplicationsPageProps()
  const queryDefinitions = useGetApplicationsQueryDefinitions()

  if (isFetchingApplications || isLoadingApplications) {
    return <Loading />
  }

  if(!pageProps) return null

  return (
    <PagePropsContextProvider<ApplicationsPageProps> pageProps={pageProps}>
      {/* @ts-ignore */}
      <QueryParameterContextProvider<GetApplicationsQuerys>
        initialQueryParameters={{
          sort: '-submittedAt',
          pageSize: '20',
          pageNumber: '1',
        }}
        queryDefinitions={queryDefinitions}
      >
        {children}
      </QueryParameterContextProvider>
    </PagePropsContextProvider>
  )
}
