import { DocumentTags } from './enums'

export function getLanguageProficiencyFieldsInfo(testType: string) {
  switch (testType) {
    case 'IELTS':
      return {
        minOverall: 0,
        maxOverall: 9,
        minSubscore: 0,
        maxSubscore: 9,
        step: 0.5,
        codeLabel: 'TRF number',
        fileType: DocumentTags.LANGUAGE_TEST_IELTS,
      }
    case 'TOEFL':
      return {
        minOverall: 0,
        maxOverall: 120,
        minSubscore: 0,
        maxSubscore: 30,
        step: 1,
        codeLabel: 'Appointment number',
        fileType: DocumentTags.LANGUAGE_TEST_TOEFL,
      }
    case 'PTE':
      return {
        minOverall: 10,
        maxOverall: 90,
        minSubscore: 10,
        maxSubscore: 90,
        step: 1,
        codeLabel: 'Score report code',
        fileType: DocumentTags.LANGUAGE_TEST_PTE,
      }
    case 'OTHER':
      return {
        fileType: DocumentTags.LANGUAGE_TEST_OTHER,
      }
  }

  return null
}

export type Subscores = 'speaking' | 'writing' | 'reading' | 'listening'

export function getSubscoresOrder(
  testType: string,
): Array<{ id: string; label: string; name: Subscores }> {
  switch (testType) {
    case 'TOEFL':
      return [
        { id: crypto.randomUUID(), label: 'Reading', name: 'reading' },
        { id: crypto.randomUUID(), label: 'Listening', name: 'listening' },
        { id: crypto.randomUUID(), label: 'Speaking', name: 'speaking' },
        { id: crypto.randomUUID(), label: 'Writing', name: 'writing' },
      ]
    case 'PTE':
      return [
        { id: crypto.randomUUID(), label: 'Listening', name: 'listening' },
        { id: crypto.randomUUID(), label: 'Reading', name: 'reading' },
        { id: crypto.randomUUID(), label: 'Speaking', name: 'speaking' },
        { id: crypto.randomUUID(), label: 'Writing', name: 'writing' },
      ]
    default:
      return [
        { id: crypto.randomUUID(), label: 'Listening', name: 'listening' },
        { id: crypto.randomUUID(), label: 'Reading', name: 'reading' },
        { id: crypto.randomUUID(), label: 'Writing', name: 'writing' },
        { id: crypto.randomUUID(), label: 'Speaking', name: 'speaking' },
      ]
  }
}

export function validateSubscore(testType: string, score: number): true | string {
  const fieldsInfo = getLanguageProficiencyFieldsInfo(testType)
  if (!fieldsInfo) return true

  const { minSubscore, maxSubscore } = fieldsInfo

  if (score < (minSubscore || 0) || score > (maxSubscore || 0)) {
    return 'Invalid score'
  }

  return true
}
