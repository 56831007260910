import { useAuthQuery } from '../auth'
import { ApiAgentRequest, request } from '../../utils/request'
import { ApplicationsPageProps } from './types'

type RawApplicationsPagePropsResponse = {
  data: ApplicationsPageProps
}

export function useGetApplicationsPageProps() {
  const { data, isLoading, isFetching } = useAuthQuery(
    ['page-props', { page: 'applications'}],
    async () => {
      return await request<RawApplicationsPagePropsResponse>(
        new ApiAgentRequest(`/applications/page-props`, {
          method: 'GET',
        }),
        {
          isExpectedResponse: (res): res is RawApplicationsPagePropsResponse => res,
        },
      )
    },
    {
      enabled: true,
    },
  )

  return {
    isLoadingApplications: isLoading,
    isFetchingApplications: isFetching,
    pageProps: data?.data || null,
  }
}
