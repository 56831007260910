import { Button, Dialog, Link, Text } from '@applyboard/crystal-ui'
import { Dispatch, SetStateAction, useState } from 'react'
import { nanoid } from 'nanoid'
import { RequestDeferralForm } from './RequestDeferralForm'
import { DeferralOptions } from '../../../hooks'

type RequestDeferralDialogProps = {
  open: boolean
  onOpenChange: Dispatch<SetStateAction<boolean>>
  applicationId: string
  deferralOptions: DeferralOptions
}

export function RequestDeferralDialog(props: RequestDeferralDialogProps) {
  const [isUpdating, setIsUpdating] = useState(false)
  const formId = nanoid()

  const haveOpenIntakes = !!props.deferralOptions?.availableIntakes?.length

  return (
    <Dialog open={props.open} onOpenChange={props.onOpenChange}>
      <Dialog.Heading>Request a deferral</Dialog.Heading>
      <Dialog.Content>
        {haveOpenIntakes ? (
          <RequestDeferralForm
            id={formId}
            onOpenChange={props.onOpenChange}
            setIsUpdating={setIsUpdating}
            applicationId={props.applicationId}
            deferralOptions={props.deferralOptions}
          />
        ) : (
          <Text>
            Your program does not have any other open intakes at the moment. Alternatively, you can{' '}
            <Link href="/applications/new">start a new application</Link>.
          </Text>
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.CloseButton width="fill">Cancel</Dialog.CloseButton>
        {haveOpenIntakes ? (
          <Button width="fill" intent="primary" form={formId} type="submit" loading={isUpdating}>
            Submit request
          </Button>
        ) : null}
      </Dialog.Actions>
    </Dialog>
  )
}
