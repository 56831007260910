import { CheckMarkOutlineIcon, ClockOutlineIcon, CloseOutlineIcon } from '@applyboard/ui-icons'

import { ApplicationDetailsIntent } from '../types'

export function getIcon(intent: ApplicationDetailsIntent) {
  switch (intent) {
    case 'positive':
      return CheckMarkOutlineIcon
    case 'negative':
      return CloseOutlineIcon
    default:
      return ClockOutlineIcon
  }
}
