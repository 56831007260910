import { Dispatch, SetStateAction } from 'react'
import { Button, Dialog, Text } from '@applyboard/crystal-ui'

type IntakeClosedDialogProps = {
  open: boolean
  onOpenChange: Dispatch<SetStateAction<boolean>>
  onEditProgram: () => void
}

export function IntakeClosedDialog(props: IntakeClosedDialogProps) {
  return (
    <Dialog open={props.open} onOpenChange={props.onOpenChange}>
      <Dialog.Heading>Intake closed</Dialog.Heading>
      <Dialog.Content>
        <Text>
          Your application cannot be submitted for the selected intake. You may select a future
          intake for the same program or a different program to successfully submit your
          application.
        </Text>
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.CloseButton width="fill">Cancel</Dialog.CloseButton>
        <Button
          width="fill"
          intent="primary"
          onClick={() => {
            props.onOpenChange(false)
            props.onEditProgram()
          }}
        >
          Change program/intake
        </Button>
      </Dialog.Actions>
    </Dialog>
  )
}
