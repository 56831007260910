import { Box, Button, MenuButton } from '@applyboard/crystal-ui'
import { ClockOutlineIcon, MoreVertOutlineIcon } from '@applyboard/ui-icons'
import { useState } from 'react'
import { RequestDeferralDialog } from '../RequestDeferralDialog'
import { useGetApplication } from '../../../hooks'
import { Loading } from '../../../components/Loading'

type TopMenuProps = {
  applicationId: string
}

export function TopMenu(props: TopMenuProps) {
  const { deferralOptions, isLoadingApplication } = useGetApplication({
    id: props.applicationId,
  })
  const [openMenuButton, setOpenMenuButton] = useState(false)
  const [openRequestDeferralDialog, setOpenRequestDeferralDialog] = useState(false)

  if (isLoadingApplication) {
    return <Loading />
  }

  if (!deferralOptions) {
    return null
  }

  return (
    <>
      <Box hideBelow="md">
        <Button
          leadIcon={ClockOutlineIcon}
          onClick={() => setOpenRequestDeferralDialog(true)}
          intent="secondary"
          emphasis="highlighted"
        >
          Request a deferral
        </Button>
      </Box>
      <Box hideAbove="md">
        <MenuButton open={openMenuButton} onOpenChange={setOpenMenuButton} alignment="right">
          <MenuButton.Trigger>
            <Button
              aria-label="Open menu"
              leadIcon={MoreVertOutlineIcon}
              onClick={() => setOpenMenuButton(open => !open)}
              emphasis="highlighted"
            />
          </MenuButton.Trigger>
          <MenuButton.Button
            id="request-deferral"
            icon={ClockOutlineIcon}
            onClick={() => setOpenRequestDeferralDialog(true)}
          >
            Request a deferral
          </MenuButton.Button>
        </MenuButton>
      </Box>

      <RequestDeferralDialog
        open={openRequestDeferralDialog}
        onOpenChange={setOpenRequestDeferralDialog}
        applicationId={props.applicationId}
        deferralOptions={deferralOptions}
      />
    </>
  )
}