import { ClipboardOutlineIcon, PersonOutlineIcon } from '@applyboard/ui-icons'
import { DesktopSidebar } from './DesktopSidebar'
import { MobileSidebar } from './MobileSidebar'

type SidebarProps = {
  isDesktopSidebarOpen: boolean
  isMobileSidebarOpen: boolean
  onMobileSidebarOpenChange: (open: boolean) => void
}

export function Sidebar(props: SidebarProps) {
  const menuItems = [
    {
      id: crypto.randomUUID(),
      href: '/',
      label: 'My Applications',
      icon: ClipboardOutlineIcon,
      selected: true,
    },
  ]

  return (
    <>
      <MobileSidebar
        open={props.isMobileSidebarOpen}
        onOpenChange={props.onMobileSidebarOpenChange}
        menuItems={menuItems}
      />
      <DesktopSidebar open={props.isDesktopSidebarOpen} menuItems={menuItems} />
    </>
  )
}
