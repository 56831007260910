import { Chip, ChipGroup, Flex } from "@applyboard/crystal-ui";
import { useProgramSelectionFilterSummary, useProgramFilterSearchParams } from "../../../hooks";

export function FilterSummary() {
  
  const { clearFilters } = useProgramFilterSearchParams();
  const { filterSummaryItems, removeValue, isLoading } = useProgramSelectionFilterSummary();

  if(isLoading || !filterSummaryItems.length) return null

  return (
    <section aria-label="Programs Filter Summary">
      <Flex gap={1} justify="between" align="center">
        <ChipGroup size="sm" width="hug" lines={1} variant="outlined">
          {filterSummaryItems.map((summaryItem) => {
            return (
              <Chip
                key={crypto.randomUUID()}
                onDelete={() => {
                  removeValue(summaryItem.filterKey, summaryItem.value)
                }}
              >
                {summaryItem.label}
              </Chip>
            )
          })}
          <ChipGroup.ClearAllButton
            key="clear-all"
            onClick={() => {
              clearFilters();
            }}
          />
        </ChipGroup>
      </Flex>
    </section>
  );
}
