import { useMutation, useQueryClient } from '@tanstack/react-query'
import { OAuth2TokenResponse } from 'schools-domain-backend-utils'
import { AuthAgentRequest, request } from '../../utils'

// TODO: replace with response interface defined in BE
export interface RawLoginResponse {
  id_token: string
  access_token: string
  refresh_token: string
}

export interface LoginParams {
  email: string
  password: string
}

interface ILogin {
  setCredentials: (response: OAuth2TokenResponse) => void
}

export function useLogin({ setCredentials }: ILogin) {
  const queryClient = useQueryClient()
  const { data, error, isPending, mutate } = useMutation({
    mutationFn: async ({ email, password }: LoginParams): Promise<OAuth2TokenResponse> => {
      const response = await request<RawLoginResponse>(
        new AuthAgentRequest('/pre/oauth2/token', {
          method: 'POST',
          body: {
            grant_type: 'client_credentials',
            username: email,
            password: password,
          },
        }),
        {
          isExpectedResponse: (res): res is RawLoginResponse => res,
        },
      )
      setCredentials(response)
      queryClient.clear()
      return response
    },
  })

  return {
    loginData: data,
    loginError: error,
    login: mutate,
    isLoggingIn: isPending,
  }
}
