import { ApplicationResource, RequestedDocuments } from 'applications-types-lib'
import { SetAllFieldsOptional } from 'schools-domain-backend-utils'
import { useUpdateApplication } from '.'
import { DocumentTags } from '../utils/enums'
import { useToasts } from '@applyboard/crystal-ui'
import { useQueryClient } from '@tanstack/react-query'
import { UploadFileData } from '../components/Application'

type UpdateRequestedDocumentsProps = {
  applicationId: string
  index: number
}

type UpdateRequestedDocumentsParams = {
  requestedDocumentsId: string
  requestedDocuments: SetAllFieldsOptional<RequestedDocuments[string]>
  documentType: DocumentTags
  dataFiles: Record<string, UploadFileData | null>
  additionalData?: SetAllFieldsOptional<Omit<ApplicationResource['attributes'], 'files'>>
  onSuccess: () => void
  sectionReference?: string
}

export function useUpdateRequestedDocuments(props: UpdateRequestedDocumentsProps) {
  const queryClient = useQueryClient()
  const toast = useToasts()
  const { isUpdatingApplication, updateApplication } = useUpdateApplication({
    id: props.applicationId,
  })

  const updateRequestedDocuments = (params: UpdateRequestedDocumentsParams) => {
    const documents = params.requestedDocuments?.documents?.map((doc, index) => {
      if (index === props.index && (doc?.type as string) === params.documentType) {
        // should we only do this after successful upload?
        const fileDataIds = Object.keys(params.dataFiles)

        return {
          ...doc,
          fileDataIds,
          sectionReference: params.sectionReference || doc?.sectionReference,
        }
      }

      return doc
    })

    if (documents) {
      updateApplication(
        {
          attributes: {
            ...(params.additionalData ?? {}),
            requestedDocuments: {
              [params.requestedDocumentsId]: {
                documents,
              },
            },
          },
          files: params.dataFiles,
        },
        {
          onSuccess: response => {
            queryClient.setQueryData(['applications', response.data.id], {
              data: response.data,
            })

            toast.positive('Request updated successfully')

            params.onSuccess()
          },
          onError: err => {
            if (err instanceof Error) {
              if (
                err.status?.toString() === '400' &&
                !!err.message.match(
                  /^(Cannot modify document request )(\S*?)( while its status is )(?!PENDING).*$/i,
                )
              ) {
                queryClient.invalidateQueries({
                  queryKey: ['applications', props.applicationId],
                })

                params.onSuccess()
              }

              toast.negative(new Error(err.message))
            }
          },
        },
      )
    } else {
      toast.negative(new Error('An error occurred'))
    }
  }

  return {
    isUpdatingApplication,
    updateRequestedDocuments,
  }
}
