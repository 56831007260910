import {
  ApplicationResourceAttributes,
  PersonalInformation,
  ProgramIntakeTerm,
  RefinedVersionedApplicationResourceWithFiles,
} from 'applications-types-lib'
import { SetAllFieldsOptional } from 'schools-domain-backend-utils'
import { useAuthMutation } from '../auth'
import { ApiAgentRequest, request } from '../../utils'
import { ApplicationState } from './types'

type CreateApplicationParams = {
  intakeId: Exclude<ProgramIntakeTerm, undefined>['id']
  personalInformation: SetAllFieldsOptional<PersonalInformation>
}

type RawApplicationsResponse = {
  data: RefinedVersionedApplicationResourceWithFiles
}

export function useCreateApplication() {
  const { isPending, mutate, data } = useAuthMutation(async (params: CreateApplicationParams) => {
    const programData: SetAllFieldsOptional<ApplicationResourceAttributes['programSelected']> = {
      priority: 0,
      programIntakeId: params.intakeId,
    }

    return await request<RawApplicationsResponse>(
      new ApiAgentRequest('/applications', {
        method: 'POST',
        body: {
          data: {
            type: 'application',
            attributes: {
              programSelected: programData,
              applicationState: ApplicationState.DRAFT,
              personalInformation: params.personalInformation,
            },
          },
        },
      }),
      {
        isExpectedResponse: (res): res is RawApplicationsResponse => res,
      },
    )
  })

  return {
    isCreatingApplication: isPending,
    createApplication: mutate,
    application: data?.data || null,
  }
}
