import { useToasts, Flex, Heading, Button, Text } from '@applyboard/crystal-ui'
import styled from '@emotion/styled'
import { useState } from 'react'
import { OnBoardingBox } from '../../components/OnBoardingBox'
import { useResendSignUpConfirmation } from '../../hooks'
import { VerifySignedUpEmail } from '../SignUpPage/VerifySignedUpEmail'

export function SignUpExpiredPage(props: { readonly username: string }) {
  const toast = useToasts()
  const [isConfirmationSent, setIsConfirmationSent] = useState(false)

  const { isSendingConfirmation, resendConfirmation } = useResendSignUpConfirmation({
    id: props.username,
  })
  const TextWrapper = styled.div(() => ({
    maxWidth: 450,
  }))

  return (
    <OnBoardingBox>
      {isConfirmationSent ? (
        <VerifySignedUpEmail userId={props.username} />
      ) : (
        <Flex direction="column" gap={10}>
          <Flex direction="column" align="center" gap={6}>
            <img src="/alert.svg" alt="Email illustration" />
            <Heading level={1} variant="headlineM">
              Verification link expired
            </Heading>
            <TextWrapper>
              <Text align="center">
                Unfortunately, the link we provided has expired. Try sending verification email
                again.
              </Text>
            </TextWrapper>
          </Flex>
          <Button
            intent="primary"
            variant="outlined"
            width="fill"
            onClick={() => {
              resendConfirmation(undefined, {
                onSuccess: () => {
                  toast.positive('Verification email sent')
                  setIsConfirmationSent(true)
                },
                onError: err => {
                  if (err instanceof Error) {
                    toast.negative(new Error(err.message))
                  }
                },
              })
            }}
            loading={isSendingConfirmation}
          >
            Resend link
          </Button>
        </Flex>
      )}
    </OnBoardingBox>
  )
}
