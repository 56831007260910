import { Avatar } from '@applyboard/crystal-ui'

import { ApplicationDetailsIntent } from '../types'

import { getIcon } from './utils'

type ApplicationDetailCardTitleIconProps = Readonly<{
  intent: ApplicationDetailsIntent
}>

export function ApplicationDetailCardTitleIcon(props: ApplicationDetailCardTitleIconProps) {
  return <Avatar intent={props.intent} variant="highlighted" icon={getIcon(props.intent)} />
}
