import { Flex } from '@applyboard/crystal-ui'
import { DesktopHeader } from './DesktopHeader'
import { MobileHeader } from './MobileHeader'
import { StudentApplication } from '../types'

type ApplicationHeaderProps = {
  application: StudentApplication
}

export function ApplicationHeader(props: ApplicationHeaderProps) {
  return (
    <>
      <Flex hideAbove="md" direction="column">
        <MobileHeader application={props.application} />
      </Flex>
      <Flex hideBelow="md" direction="column">
        <DesktopHeader application={props.application} />
      </Flex>
    </>
  )
}
