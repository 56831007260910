import { getConfig } from '../../config'

export function getBaseUrl(): string {
  if (process.env.NODE_ENV === 'development') {
    const config = getConfig()
    return config.baseUrl
  }

  return window.location.origin
}
