import { Button } from '@applyboard/crystal-ui'
import { ApplicationResource } from 'applications-types-lib'
import { useEffect } from 'react'
import { useGetApplication } from '../../hooks'
import styled from '@emotion/styled'
import { useFlywireAction } from '../../hooks/applications/useFlywireAction'

declare global {
  interface Window {
    flywire?: any
  }
}

export type FlywireButtonProps = {
  application: ApplicationResource
  onClick: () => void
}

export function FlywireButton(props: FlywireButtonProps): JSX.Element {

  const { isLoadingApplication } = useGetApplication({ id: props.application.id })
  const { hiddenButtonRef, handleOnClick, setFlywireDialogTrigger } = useFlywireAction({
    applicationId: props.application.id,
    onSuccess: props.onClick,
  })

  useEffect(() => {
    setFlywireDialogTrigger(props.application)
  })

  return (
    <>
      <Button width="fill" intent="primary" loading={isLoadingApplication} onClick={handleOnClick}>
        Yes, continue to pay
      </Button>
      <HiddenButton ref={hiddenButtonRef} id="checkout-button" />
    </>
  )
}

const HiddenButton = styled.button({
  display: 'none',
})
