import { Tag } from '@applyboard/crystal-ui'

type ProgramLengthProps = {
  length: number
  unit: 'years' | 'months' | 'weeks'
}

export function ProgramLength(props: ProgramLengthProps) {
  switch (props.unit) {
    case 'years':
      return <Tag intent="secondary">{`${props.length}-Years`}</Tag>
    case 'months':
      return <Tag intent="secondary">{`${props.length}-Months`}</Tag>
    case 'weeks':
      return <Tag intent="secondary">{`${props.length}-Weeks`}</Tag>
    default:
      return null
  }
}
