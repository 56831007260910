import { Box } from "@applyboard/crystal-ui";
import { DesktopFilters, MobileFilters } from "./filters";
import { ApplicationsPageProps } from "../../hooks";
import { usePageProps } from "../../context";

export function ApplicationFilters() {
  const { referenceData } = usePageProps<ApplicationsPageProps>();

  if(!referenceData.hasApplications) return null

  return (
    <section aria-label="application filters">
      <Box hideBelow='md'>
        <DesktopFilters/>
      </Box>
      <Box hideAbove='md'>
        <MobileFilters/>
      </Box>
    </section>
  )
}
