import styled from '@emotion/styled'
import { up } from '../../utils'
import { Text } from '@applyboard/crystal-ui'

export function AccessibilityLinks() {
  return (
    <Wrapper>
      <Skip
        href="#"
        onClick={(e) => {
          e.preventDefault()
          const container: (HTMLElement | null) = document.querySelector('main h1')

          if (container) {
            container.tabIndex = -1
            container.focus()
            setTimeout(() => container.removeAttribute('tabIndex'), 1000)
          }
        }}
      >
        <Text variant="bodyS">Skip to main content</Text>
      </Skip>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  position: 'absolute',
  top: 75,
  left: 10,
  zIndex: 9999,
  [up('sm')]: {
    left: 80,
  },
})

const Skip = styled.a({
  ':not(:focus)': {
    clip: 'rect(1px,1px,1px,1px)',
    overflow: 'hidden',
    position: 'absolute',
    padding: 0,
  },
  ':focus': {
    border: '1px solid #1E6DEB',
    borderRadius: 2,
    padding: '3px 6px',
    display: 'inline-block',
  },
})