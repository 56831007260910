import { useAuthQuery } from '../auth'
import { ApiAgentRequest, convertToUrlSearchParams, request } from '../../utils'
import { RawProgamsResponse } from './types'

export type ProgramsSearchQuery = {
  'filter[q]'?: string;
  'page[size]': number;
  'page[number]': number;
};

export function useGetPrograms(
  searchQuery: ProgramsSearchQuery,
  options?: { enabled?: boolean}) {
  const { data, isLoading, isFetching } = useAuthQuery(
    ['programs', searchQuery],
    async () => {
      return await request<RawProgamsResponse>(
        new ApiAgentRequest(`/programs?${convertToUrlSearchParams(searchQuery)}`, {
          method: 'GET',
        }),
        {
          isExpectedResponse: (res): res is RawProgamsResponse => res,
        },
      )
    },
    {
      placeholderData: (previousData) => previousData,
      enabled: options?.enabled === undefined ? true : options.enabled,
    }
  )

  return {
    isLoadingPrograms: isLoading,
    isFetchingPrograms: isFetching,
    programs: data?.data || [],
  }
}
