import { CheckCircleFillIcon, CircleFillIcon } from '@applyboard/ui-icons'
import styled from '@emotion/styled'

type TimelineItemDotProps = {
  isSelected: boolean
  isCompleted: boolean
}

export function TimelineItemDot(props: TimelineItemDotProps) {
  if (props.isSelected) {
    return <CircleFillIcon intent="primary" />
  }

  if (props.isCompleted) {
    return <CheckCircleFillIcon intent="positive" />
  }

  return <InactiveCircle />
}

const InactiveCircle = styled.div(({ theme }) => ({
  width: 20,
  height: 20,
  border: `2px solid ${theme.colors.borderInactive}`,
  borderRadius: '50%',
}))
