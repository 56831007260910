import { BaseRequest } from './BaseRequest'
import { getBaseUrl } from './getBaseUrl'

// TODO: delete and replace uses with ApiAgentRequest
export class AuthAgentRequest extends BaseRequest {
  constructor(
    endpoint: string,
    options: {
      body?: object
      headers?: Record<string, string>
      method?: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT'
    } = {},
  ) {
    const baseUrl = getBaseUrl()

    const url: string = `${baseUrl}/api${endpoint}`
    options.headers = {
      'Content-Type': 'application/json',
      ...options.headers,
    }
    super(url, options)
  }
}
