import { useCallback, useMemo } from "react";

import { useProgramFilterSearchParams } from "./applications";
import { format } from "date-fns";
import { convertTimelessDateStrToLocalDate } from "../utils";
import { useGetCampuses } from "./applications/useGetCampuses";
import { FilterSummaryItem } from "./types";
import { useGetIntakeTerms } from "./applications/useGetIntakeTerms";

const FILTER_SUMMARY_KEYS = ['campuses', 'startDates', 'statuses'];

export function useProgramSelectionFilterSummary() {
  const { filters, updateFilters, clearFilters } = useProgramFilterSearchParams();
  const { intakeTerms, isLoadingIntakeTerms } = useGetIntakeTerms({
    isArchived: false,
  })
  const { campuses, isLoadingCampuses } = useGetCampuses()

  const campusById = useMemo(() => {
    return filters.campuses.reduce((acc: Record<string, string>, id: string) => {
      const intakeData = campuses.find(item => item.id === id);
      if (intakeData) {
        acc[intakeData.id] = intakeData.attributes.name;
      }
      return acc;
    }, {} as Record<string, string>);
  }, [filters.campuses, campuses]);

  const startDatesById = useMemo(() => {
    return filters.startDates.reduce((acc: Record<string, string>, id: string) => {
      const intakeData = intakeTerms?.data.find(item => item.id === id);
      if (intakeData) {
        acc[id] = format(convertTimelessDateStrToLocalDate(intakeData.attributes.startsOn), 'MMM yyyy');
      }
      return acc;
    }, {} as Record<string, string>);
  }, [filters.startDates, intakeTerms]);

  const statusesById = useMemo(() => {
    return filters.statuses.reduce((acc: Record<string, string>, status: string) => {
      const friendlyName = (() => {
        switch (status) {
          case 'OPEN':
            return 'Open';
          case 'WILL_OPEN':
            return 'Opening Soon';
          case 'CLOSED':
            return 'Closed';
          default:
            return null;
        }
      })();
  
      if (friendlyName) {
        acc[status] = friendlyName;
      }
  
      return acc;
    }, {} as Record<string, string>);
  }, [filters.statuses]);

  const campusSummaryItems = useCallback((filterKey: string, filterValue: string[]) => {
    return filterValue.reduce((acc, value) => {
      if(!filters[filterKey].includes(value)) return acc
      acc.push({
        filterKey: filterKey,
        value: value,
        label: campusById[value]
      })
      return acc
    }, [] as FilterSummaryItem[])
  }, [campusById, filters])

  const startDatesSummaryItems = useCallback((filterKey: string, filterValue: string[]) => {
    return filterValue.reduce((acc, value) => {
      if(!startDatesById[value]) return acc
      acc.push({
        filterKey: filterKey,
        value: value,
        label: startDatesById[value]
      })
      return acc
    }, [] as FilterSummaryItem[])
  }, [startDatesById])

  const statusesSummaryItems = useCallback((filterKey: string, filterValue: string[]) => {
    return filterValue.reduce((acc, value) => {
      if(!statusesById[value]) return acc
      acc.push({
        filterKey: filterKey,
        value: value,
        label: statusesById[value]
      })
      return acc
    }, [] as FilterSummaryItem[])
  }, [statusesById])

  const removeValue = useCallback((filterKey: string, removedValue: string) => {
    updateFilters({
      ...filters,
      [filterKey]: filters[filterKey].filter((value: string) => value !== removedValue)
    });
  }, [filters, updateFilters]);

  const filterSummaryItems: FilterSummaryItem[] = useMemo(() => {
    let items:FilterSummaryItem[] = []
    FILTER_SUMMARY_KEYS.forEach((filterKey) => {
      const filterValue = filters[filterKey]
      switch(filterKey) {
        case 'campuses': 
          items = items.concat(campusSummaryItems(filterKey, filterValue))
          return
        case 'startDates':
          items = items.concat(startDatesSummaryItems(filterKey, filterValue))
          return;
        case 'statuses':
          items = items.concat(statusesSummaryItems(filterKey, filterValue))
          return;
      }
    })
    items = items.filter(n => n)
    return items
  }, [campusSummaryItems, startDatesSummaryItems, statusesSummaryItems, filters])

  return {
    filters,
    filterSummaryItems,
    removeValue,
    clearFilters,
    isLoading: isLoadingCampuses || isLoadingIntakeTerms,
  }
}
