import { useCallback } from 'react';
import { QueryParametersKey, QueryParametersValue, useQueryParameter } from '../context';
import { isNumber } from 'lodash';

export function useFilterField<FilterKey extends QueryParametersKey>(filterKey: FilterKey) {
  const { queryParameters, setQueryParameters } = useQueryParameter()

  if (!queryParameters.hasOwnProperty(filterKey)) {
    throw new Error(`${filterKey} must be a defined filter key`);
  }

  const setValue = useCallback((newValue: QueryParametersValue<FilterKey>) => {
    setQueryParameters({ pageNumber: 1, [filterKey]: newValue });
  }, [filterKey, setQueryParameters]);

  function getValue() {
    const value = queryParameters[filterKey]
    if (isNumber(value) || filterKey === 'submissionDate') {
      return value
    }

    if (filterKey === 'status') {
      return value && value.length ? decodeURIComponent(value).split(',') : []
    }

    return value ? decodeURIComponent(value) : value
  }

  return { value: getValue(), setValue };
}
