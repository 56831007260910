import { useQuery } from '@tanstack/react-query'
import { OAuth2TokenResponse } from 'schools-domain-backend-utils'
import { AuthAgentRequest, GenericError, request } from '../../utils'

interface RawRefreshResponse {
  id_token: string
  access_token: string
}

interface IRefreshToken {
  getRefreshToken: () => string
  setCredentials: (obj: OAuth2TokenResponse) => void
}

export function useRefreshToken({ getRefreshToken, setCredentials }: IRefreshToken) {
  const { data, isFetching, refetch, error } = useQuery<string, GenericError>({
    queryKey: ['refreshAccessToken'],
    queryFn: async (): Promise<string> => {
      const response = await request<OAuth2TokenResponse>(
        new AuthAgentRequest('/pre/oauth2/token', {
          method: 'POST',
          body: {
            grant_type: 'refresh_token',
            refresh_token: getRefreshToken(),
          },
        }),
        {
          isExpectedResponse: (res): res is RawRefreshResponse => res,
        },
      )
      setCredentials(response)
      return response.access_token
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: false,
  })

  return {
    accessToken: data,
    isRefreshingAccessToken: isFetching,
    refreshAccessToken: refetch,
    refreshAccessTokenError: error,
  }
}
