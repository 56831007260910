import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { DocumentType, FileData } from 'applications-types-lib'
import { UploadFileData } from '../types'

interface IApplicationFormContext {
  addPendingUpload: (fileId: string, uploadFileData: UploadFileData) => void
  addPendingDelete: (fileId: string) => void
  getObservableFiles: (filters: {
    fileType?: string | string[]
    sectionReference?: string
  }) => Record<string, Omit<UploadFileData, 'file'>>
  pendingFileUploadState: Record<string, UploadFileData | null>
  pendingUpload: Record<string, UploadFileData>
  resetFiles: (fileData: FileData) => void
  uploadedFiles: FileData
  visibleFileState: Record<string, Omit<UploadFileData, 'file'>>
}

const ApplicationFormContext = createContext<IApplicationFormContext | undefined>(undefined)

export function useApplicationFormContext(): IApplicationFormContext {
  return useContext(ApplicationFormContext) as IApplicationFormContext
}

export function ApplicationForm({
  children,
  fileData,
}: {
  children: React.ReactNode
  fileData?: FileData
}) {
  const [pendingDelete, setPendingDelete] = useState<string[]>([])
  const [pendingUpload, setPendingUpload] = useState<Record<string, UploadFileData>>({})
  const [uploadedFiles, setUploadedFiles] = useState<FileData>(fileData || {})

  const resetFiles = useCallback((fileData: FileData) => {
    setPendingDelete([])
    setPendingUpload({})
    setUploadedFiles(fileData || {})
  }, [])

  // file state to display
  const visibleFileState: Record<string, Omit<UploadFileData, 'file'>> = useMemo(() => {
    const visibleUploadedFiles = Object.keys(uploadedFiles).reduce(
      (acc, id) => {
        if (!uploadedFiles[id]) return acc

        acc[id] = {
          contentType: uploadedFiles[id]?.contentType as string,
          fileName: uploadedFiles[id]?.fileName as string,
          hash: uploadedFiles[id]?.hash as string,
          type: uploadedFiles[id]?.type as DocumentType,
          sectionReference: uploadedFiles[id]?.sectionReference as string,
        }
        return acc
      },
      {} as Record<string, Omit<UploadFileData, 'file'>>,
    )

    const result = {
      ...visibleUploadedFiles,
      ...pendingUpload,
    }

    pendingDelete.forEach(id => {
      if (result[id]) {
        delete result[id]
      }
    })

    return result
  }, [pendingDelete, pendingUpload, uploadedFiles])

  // upload state to submit, only includes pending changes
  const pendingFileUploadState: Record<string, UploadFileData | null> = useMemo(() => {
    return {
      ...pendingUpload,
      ...pendingDelete.reduce(
        (acc, fileId) => {
          acc[fileId] = null
          return acc
        },
        {} as Record<string, null>,
      ),
    }
  }, [pendingUpload, pendingDelete])

  function addPendingUpload(fileId: string, uploadFileData: UploadFileData) {
    if (uploadedFiles[fileId]) return // this should never happen

    setPendingUpload(pendingUpload => ({
      ...pendingUpload,
      [fileId]: uploadFileData,
    }))
  }

  function addPendingDelete(fileId: string) {
    if (pendingUpload[fileId]) {
      setPendingUpload(pendingUpload => {
        const { [fileId]: _, ...rest } = pendingUpload
        return rest
      })
    } else if (uploadedFiles[fileId]) {
      setPendingDelete(pendingDelete => {
        return [...pendingDelete, fileId]
      })
    }
  }

  // visible files with filtering
  function getObservableFiles(filters: {
    fileType?: string | string[]
    sectionReference?: string
  }): Record<string, Omit<UploadFileData, 'file'>> {
    return Object.entries(visibleFileState).reduce(
      (observableFiles, [fileId, fileData]) => {
        if (
          (Array.isArray(filters.fileType) && !filters.fileType.includes(fileData.type)) ||
          (!Array.isArray(filters.fileType) && filters.fileType && filters.fileType !== fileData.type) ||
          (filters.sectionReference && filters.sectionReference !== fileData.sectionReference)
        )
          return observableFiles

        return { ...observableFiles, [fileId]: fileData }
      },
      {} as Record<string, Omit<UploadFileData, 'file'>>,
    )
  }

  return (
    <ApplicationFormContext.Provider
      value={{
        addPendingDelete,
        addPendingUpload,
        getObservableFiles,
        pendingFileUploadState,
        pendingUpload,
        resetFiles,
        uploadedFiles,
        visibleFileState,
      }}
    >
      {children}
    </ApplicationFormContext.Provider>
  )
}
