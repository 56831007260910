import { useCallback, useMemo } from "react";

import { QueryParameters, usePageProps, useQueryParameter } from "../context";
import { ApplicationsPageProps } from "./applications";
import { FilterSummaryItem } from "./types";
import { Program } from "./programs";

const FILTER_SUMMARY_KEYS = ['nationality', 'programId', 'intakeTermId', 'status', 'submissionDate']
const THREE_LETTER_MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


function formatDate(date: Date) {
  return THREE_LETTER_MONTHS[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
}

export function useFilterSummary(props: { programs: Program[] }) {
  const { queryParameters, setQueryParameters } = useQueryParameter()
  const { referenceData: {
    applicationStatuses,
    intakeTerms,
    nationalities,
  } } = usePageProps<ApplicationsPageProps>()

  const programsById = useMemo(() => {
    return props.programs.reduce((acc, program) => {
      acc[program.id] = program.attributes.name
      return acc
    }, {} as Record<string, string>)
  }, [props.programs])

  const intakesById = useMemo(() => {
    return intakeTerms.reduce((acc, intake) => {
      acc[intake.id] = intake.attributes.name
      return acc
    }, {} as Record<string, string>)
  }, [intakeTerms])

  const statusesByGroupCode = useMemo(() => {
    return Object.keys(applicationStatuses).reduce((acc, status) => {
      const applicationStatus = applicationStatuses[status]
      acc[applicationStatus.groupCode] = applicationStatus.label
      return acc
    }, {} as Record<string, string>)
  }, [applicationStatuses])

  const decodeQueryParameter = useCallback((filterKey: string, value: any) => {
    if (filterKey === 'submissionDate') {
      const result: { from?: Date, to?: Date } = {}
      if(value.from?.length) {
        result.from = new Date(value.from) 
      }
      if(value.to?.length) {
        result.to = new Date(value.to) 
      }
      return result
    }

    return value && value.length ? decodeURIComponent(value).split(',') : []
  }, [])

  const decodedQueryParams = useMemo(() => {
    return Object.keys(queryParameters).reduce((acc, key) => {
      if(FILTER_SUMMARY_KEYS.includes(key)) {
        acc[key] = decodeQueryParameter(key, queryParameters[key])
      }
      return acc
    }, {} as QueryParameters)
  }, [decodeQueryParameter, queryParameters])

  const removeValue = useCallback((filterKey: string, removedValue: string) => {
    let newValue: any = undefined
    if(filterKey === 'submissionDate') {
      newValue = { to: '', from: '' }
    } else {
      newValue = decodedQueryParams[filterKey].filter((val: string) => val !== removedValue)
    }
    setQueryParameters({ pageNumber: 1, [filterKey]: newValue });
  }, [decodedQueryParams, setQueryParameters])

  const nationalitySummaryItems = useCallback((filterKey: string, filterValue: string[], ) => {
    return filterValue.reduce((acc, value) => {
      if(!nationalities[value]) return acc
      acc.push({
        filterKey: filterKey,
        value: value,
        label: nationalities[value].nationalityLabel,
      })
      return acc
    }, [] as FilterSummaryItem[])
  }, [nationalities])

  const programSummaryItems = useCallback((filterKey: string, filterValue: string[]) => {
    return filterValue.reduce((acc, value) => {
      if(!programsById[value]) return acc
      acc.push({
        filterKey: filterKey,
        value: value,
        label: programsById[value]
      })
      return acc
    }, [] as FilterSummaryItem[])
  }, [programsById])

  const intakeSummaryItems = useCallback((filterKey: string, filterValue: string[], ) => {
    return filterValue.reduce((acc, value) => {
      if(!intakesById[value]) return acc
      acc.push({
        filterKey: filterKey,
        value: value,
        label: intakesById[value],
      })
      return acc
    }, [] as FilterSummaryItem[])
  }, [intakesById])

  const statusSummaryItems = useCallback((filterKey: string, filterValue: string[], ) => {
    return filterValue.reduce((acc, value) => {
      if(!statusesByGroupCode[value]) return acc
      acc.push({
        filterKey: filterKey,
        value: value,
        label: statusesByGroupCode[value],
      })
      return acc
    }, [] as FilterSummaryItem[])
  }, [statusesByGroupCode])

  const submissionDateSummaryItems = useCallback((filterKey: string, filterValue: { from?: Date, to?: Date }, ) => {
    const result: FilterSummaryItem[] = []

    let label = undefined
    const submissionDate = 'SubmissionDate: ';
    if (filterValue.to && filterValue.from) {
      label = formatDate(filterValue.to) === formatDate(filterValue.from)
        ? submissionDate + `on ${formatDate(filterValue.to)}`
        : submissionDate + `${formatDate(filterValue.from)} - ${formatDate(filterValue.to)}`;
    } else if (filterValue.to) label = submissionDate + `until ${formatDate(filterValue.to)}`;
    else if (filterValue.from) label = submissionDate + `after ${formatDate(filterValue.from)}`

    if(!!label) {
      result.push({
        filterKey: filterKey,
        value: '', // this doesn't matter for this field
        label: label
      })
    }
    return result
  }, [])

  const filterSummaryItems: FilterSummaryItem[] = useMemo(() => {
    let items:FilterSummaryItem[] = []
    FILTER_SUMMARY_KEYS.forEach((filterKey) => {
      const filterValue = decodedQueryParams[filterKey]
      switch(filterKey) {
        case 'nationality': 
          items = items.concat(nationalitySummaryItems(filterKey, filterValue))
          return
        case 'programId': 
          items = items.concat(programSummaryItems(filterKey, filterValue))
          return
        case 'intakeTermId': 
          items = items.concat(intakeSummaryItems(filterKey, filterValue))
          return
        case 'status':
          items = items.concat(statusSummaryItems(filterKey, filterValue))
          return
        case 'submissionDate':
          items = items.concat(submissionDateSummaryItems(filterKey, filterValue))
          return
      }
    })
    items = items.filter(n => n)
    return items
  }, [decodedQueryParams, intakeSummaryItems, nationalitySummaryItems, programSummaryItems, statusSummaryItems, submissionDateSummaryItems])

  return {
    filterSummaryItems,
    removeValue
  }
}
