import { Flex, MultiCombobox } from '@applyboard/crystal-ui'
import { Loading } from '../../../components/Loading'
import { format } from 'date-fns'
import {  useProgramFilterSearchParams } from '../../../hooks'
import { convertTimelessDateStrToLocalDate } from '../../../utils/convertTimelessDateStrToLocalDate'
import { useGetCampuses } from '../../../hooks/applications/useGetCampuses'
import { useGetIntakeTerms } from '../../../hooks/applications/useGetIntakeTerms'
import styled from '@emotion/styled'

type ProgramFiltersFormProps = {
  isDesktop?: boolean
}

export function ProgramFiltersForm(props: ProgramFiltersFormProps) {
  const { filters, updateFilters } = useProgramFilterSearchParams()
  const { campuses, isLoadingCampuses } = useGetCampuses()
  const { intakeTerms, isLoadingIntakeTerms } = useGetIntakeTerms({
    isArchived: false,
  })

  if (isLoadingCampuses || isLoadingIntakeTerms) {
    return <Loading />
  }

  return (
    <Flex basis={props.isDesktop ? 'auto' : '100%'} direction={props.isDesktop ? 'row' : 'column'} gap={3}>
      <FieldWrapperStyled>
        <MultiCombobox
          value={filters.campuses}
          aria-label="Campus" 
          placeholder="Campus"
          placeholderVariant="indicator"
          onChange={(value) => {
            updateFilters({ ...filters, campuses: value })
          }}>
          {campuses.map(campus => (
            <MultiCombobox.Option
              key={`campus-${campus.id}`}
              label={campus.attributes.name}
              value={campus.id}
            />
          ))}
        </MultiCombobox>
      </FieldWrapperStyled>
      <FieldWrapperStyled>
        <MultiCombobox
          value={filters.startDates}
          aria-label="Start" 
          placeholder="Start"
          placeholderVariant="indicator"
          onChange={(value) => {
            updateFilters({ ...filters, startDates: value })
          }}>
          {intakeTerms?.data
            ? intakeTerms.data.map(intakeTerm => (
              <MultiCombobox.Option
                key={`startDates-${intakeTerm?.id}`}
                label={
                  intakeTerm?.attributes?.startsOn ?
                  format( convertTimelessDateStrToLocalDate(intakeTerm?.attributes?.startsOn), 'MMM yyyy') : 
                  ''
                }
                value={intakeTerm?.id}
              />
              ))
            : null}
        </MultiCombobox>
      </FieldWrapperStyled>
      <FieldWrapperStyled>
        <MultiCombobox 
          value={filters.statuses}  
          aria-label="Status"
          placeholder="Status"
          placeholderVariant="indicator"
          onChange={(value) => {
            updateFilters({ ...filters, statuses: value })
          }}>
          <MultiCombobox.Option label="Open" value="OPEN" />
          <MultiCombobox.Option label="Opening Soon" value="WILL_OPEN" />
          <MultiCombobox.Option label="Closed" value="CLOSED" />
        </MultiCombobox>
      </FieldWrapperStyled>
    </Flex>
  )
}

const FieldWrapperStyled = styled.div(`
  min-width: 200px;
`);