import styled from '@emotion/styled'
import { Button, Flex, Heading, Text, useToasts } from '@applyboard/crystal-ui'
import { useResendSignUpConfirmation } from '../../hooks'

type VerifySignedUpEmailProps = {
  readonly userId: string
}

export function VerifySignedUpEmail(props: VerifySignedUpEmailProps) {
  const toast = useToasts()
  const { isSendingConfirmation, resendConfirmation } = useResendSignUpConfirmation({
    id: props.userId,
  })

  return (
    <Flex direction="column" gap={10}>
      <Flex direction="column" align="center" gap={6}>
        <img src="/email-illustration.svg" alt="Email illustration" />
        <Heading level={1} variant="headlineM">
          Verify your email
        </Heading>
        <TextWrapper>
          <Text align="center">
            We’ve sent you an email with a link that you will need to click to verify your email.
            You might need to check your spam folder if you don’t see it.
          </Text>
        </TextWrapper>
      </Flex>
      <Button
        intent="primary"
        variant="outlined"
        width="fill"
        onClick={() => {
          resendConfirmation(undefined, {
            onSuccess: () => {
              toast.positive('Verification email sent')
            },
            onError: err => {
              if (err instanceof Error) {
                toast.negative(new Error(err.message))
              }
            },
          })
        }}
        loading={isSendingConfirmation}
      >
        Resend verification email
      </Button>
    </Flex>
  )
}

const TextWrapper = styled.div(() => ({
  maxWidth: 450,
}))
