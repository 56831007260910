import { Button, Drawer, Flex } from '@applyboard/crystal-ui'
import { FilterOutlineIcon } from '@applyboard/ui-icons'
import { useState } from 'react'

import { useProgramFilterSearchParams } from '../../../hooks'
import { ProgramFiltersForm } from './ProgramFiltersForm'

export function ProgramFiltersDialog() {
  const { clearFilters } = useProgramFilterSearchParams()
  const [open, setOpen] = useState(false)

  return (
    <Drawer onOpenChange={setOpen} open={open} placement="right">
      <Drawer.Trigger>
        <Button aria-label="Filter Programs" leadIcon={FilterOutlineIcon} emphasis="outlined" />
      </Drawer.Trigger>
      <Drawer.Header>Filter Programs</Drawer.Header>
      <ProgramFiltersForm />
      <Drawer.Footer>
        <Flex gap={2}>
          <Button
            width="fill"
            emphasis="highlighted"
            onClick={() => {
              clearFilters()
              setOpen(false)
            }}
          >
            Clear all
          </Button>
          <Button type="button" onClick={() => setOpen(false)} width="fill" intent="primary">
            See programs
          </Button>
        </Flex>
      </Drawer.Footer>
    </Drawer>
  )
}
