import { Flex, Heading } from '@applyboard/crystal-ui'

import { ApplicationDetailsIntent } from '../types'

import { ApplicationDetailCardTitleIcon } from './ApplicationDetailCardTitleIcon'

type ApplicationDetailCardTitleProps = Readonly<{
  title: string
  intent: ApplicationDetailsIntent
}>

export function ApplicationDetailCardTitle(props: ApplicationDetailCardTitleProps) {
  return (
    <Flex gap={3} align="center">
      <ApplicationDetailCardTitleIcon intent={props.intent} />
      <Heading variant="titleS" level={3}>
        {props.title}
      </Heading>
    </Flex>
  )
}
