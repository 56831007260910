import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { MutateFunction, UseMutationOptions, UseMutationResult } from '@tanstack/react-query'
import { useAuthManager } from './useAuthManager'
import { useAuthToken } from './useAuthToken'
import { GenericError } from '../../utils'

export function useAuthMutation<TData = unknown, TVariables = unknown, TContext = unknown>(
  mutationFn: MutateFunction<TData, GenericError, TVariables, TContext>,
  mutationOptions?: Omit<
    UseMutationOptions<TData, GenericError, TVariables, TContext>,
    'mutationFn'
  >,
): UseMutationResult<TData, GenericError, TVariables, TContext> {
  const queryClient = useQueryClient()
  const { isActiveSession, isRefreshableSession } = useAuthToken()
  const { refreshAccessToken, signout } = useAuthManager()

  const useMutationResponse = useMutation({
    mutationFn: async variables => {
      try {
        if (!isActiveSession()) {
          if (!isRefreshableSession()) throw new Error('Unable to refresh inactive session')
          await refreshAccessToken({ throwOnError: true })
        }
        return mutationFn(variables)
      } catch (e) {
        queryClient.invalidateQueries()
        signout()
        throw new Error('Unable to refresh access token')
      }
    },
    ...mutationOptions,
  })

  return useMutationResponse
}
