import { ApiAgentRequest, request } from '../../utils'
import { useAuthQuery } from '../auth'
import { GetIntakeSearchResponse } from '../../../../backend/agents-ui-api/routes/intake-search/GET/types'

type GetIntakeSearchProps = {
  pageNumber?: number
  perPage?: number
  campuses?: string[]
  intakeTerms?: string[]
  statuses?: string[]
  q?: string
}

export function useGetIntakeSearch(props?: GetIntakeSearchProps) {
  const selectedPageNumber = props?.pageNumber || 1
  const selectedPerPage = props?.perPage || 10

  const filters = [`page[number]=${selectedPageNumber}`, `page[size]=${selectedPerPage}`]

  if (props?.campuses?.length) {
    filters.push(`filter[campus.id]=${props.campuses.join(',')}`)
  }

  if (props?.intakeTerms?.length) {
    filters.push(`filter[intakeTerm.id]=${props.intakeTerms.join(',')}`)
  }

  if (props?.statuses?.length) {
    filters.push(`filter[intake.status]=${props.statuses.join(',')}`)
  }

  if (props?.q) {
    filters.push(`filter[q]=${props.q}`)
  }

  const { isLoading, isFetching, data, refetch, isStale } = useAuthQuery(
    ['intake-search', filters],
    async () => {
      return await request<GetIntakeSearchResponse>(
        new ApiAgentRequest(`/intake-search?${filters.join('&')}`, {
          method: 'GET',
        }),
        {
          isExpectedResponse: (res): res is GetIntakeSearchResponse => res,
        },
      )
    },
    {
      refetchOnWindowFocus: false,
    },
  )

  return {
    isLoadingIntakeSearch: isLoading,
    isFetchingIntakeSearch: isFetching,
    isStaleIntakeSearch: isStale,
    refetchIntakeSearch: refetch,
    intakeSearch: data?.data || [],
    intakeSearchMeta: data?.meta,
  }
}
