import { isTabCompleted } from "../../components/Application/ApplicationTimeline/utils"
import { StudentApplication } from "../../components/Application/types"

type TimelineObject = {
  key: string
  label: string
  completed: boolean
}

export function useGetTimelineItems(application: StudentApplication): TimelineObject[] {
  const timelineItems = [
    { key: 'personal-information-timeline-item', label: 'Personal Information' },
    { key: 'contact-information-timeline-item', label: 'Contact Information' },
    { key: 'citizenship-and-status-timeline-item', label: 'Citizenship and Status' },
    { key: 'education-history-timeline-item', label: 'Education History' },
    { key: 'language-proficiency-timeline-item', label: 'Language Proficiency' },
    { key: 'other-required-documents-timeline-item', label: 'Other Required Documents' },
  ]

  return timelineItems.map((timelineItem, index) => {
    return {
      ...timelineItem,
      completed: isTabCompleted({
        index,
        application: application,
      }),
    }
  })
}
