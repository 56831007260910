import { Flex, Text } from '@applyboard/crystal-ui'
import { DocumentCheckIcon } from '@applyboard/ui-icons'
import styled from '@emotion/styled'
import { format } from 'date-fns'
import { DocumentTags } from '../../../utils/enums'
import {
  ApplicationDocument,
  LanguageExamHistoryRecord,
  PassportHistoryRecord,
} from '../ApplicationDocuments'
import { LanguageExamHistoryEntryFields } from './LanguageExamHistoryEntryFields'
import { PassportHistoryEntryFields } from './PassportHistoryEntryFields'
import { LanguageOtherExamHistoryEntryFields } from './LanguageOtherExamHistoryEntryFields'

export type FileHistoryEntryProps = {
  record: ApplicationDocument
}

export function FileHistoryEntry(props: FileHistoryEntryProps) {
  return (
    <Flex direction="column" px={2} gap={5}>
      <HistoryFieldSet record={props.record} />
      <Flex direction="column" gap={0}>
        <FileChip>
          <FileLabel fileName={props.record.name} link={props.record.url}>
            <DocumentCheckIcon size="md" variant="outline" intent="primary" />
            <Flex direction="row">
              <Text intent="primary" variant="labelM">
                {props.record.name}
              </Text>
            </Flex>
          </FileLabel>
        </FileChip>
        {!props.record.isBlankDatePlaceholder() ? (
          <Text contrast="low" variant={'labelS'}>
            {`Uploaded on ${format(props.record.uploadDate, 'dd MMM yyyy')}`}
          </Text>
        ) : null}
      </Flex>
    </Flex>
  )
}

function FileLabel({
  children,
  fileName,
  link,
}: {
  children: React.ReactNode
  fileName: string
  link?: string
}) {
  if (!link) {
    return <FileDiv>{children}</FileDiv>
  }

  return (
    <FileLink href={link} target="_blank" aria-label={`Download file ${fileName}`}>
      {children}
    </FileLink>
  )
}

function HistoryFieldSet({ record }: { record: ApplicationDocument }): JSX.Element | null {
  switch (record.tags[0]) {
    case DocumentTags.PASSPORT:
      return <PassportHistoryEntryFields record={record as PassportHistoryRecord} />
    case DocumentTags.LANGUAGE_TEST_OTHER:
      return <LanguageOtherExamHistoryEntryFields record={record as LanguageExamHistoryRecord} />
    case DocumentTags.LANGUAGE_TEST_IELTS:
    case DocumentTags.LANGUAGE_TEST_PTE:
    case DocumentTags.LANGUAGE_TEST_TOEFL:
      return <LanguageExamHistoryEntryFields record={record as LanguageExamHistoryRecord} />
    default:
      return null
  }
}

const FileChip = styled.div(() => ({
  alignItems: 'center',
  backgroundColor: 'rgb(228, 240, 253)',
  borderRadius: '8px',
  display: 'flex',
  width: '100%',
  height: '48px',
  justifyContent: 'space-between',
}))

const FileLink = styled.a(() => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  gap: '16px',
  paddingLeft: '16px',
  borderRadius: '8px 0 0 8px',
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: 'rgb(210, 222, 247)',
  },
}))

const FileDiv = styled.div((props: { intent?: string }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  gap: '16px',
  paddingLeft: '16px',
  borderRadius: '8px 0 0 8px',
  textDecoration: 'none',
}))
