import { format } from 'date-fns'
import { Card, Flex, Heading, Text } from '@applyboard/crystal-ui'
import { ReviewRequirementsButton } from './ReviewRequirementsButton'
import { hasApplicationFee } from '../../../utils'
import { StudentApplication } from '../types'

type DesktopHeaderProps = {
  application: StudentApplication
  showProgramName?: boolean
}

export function DesktopHeader({ application, showProgramName = true }: DesktopHeaderProps) {
  const program = application?.attributes?.programSelected

  return (
    <Card emphasis="highlighted" aria-label="Application Information">
      <Flex direction="column" gap={2}>
        {showProgramName ? (
          <Flex pb={4}>
            <Heading level={2} variant="titleS">
              {program?.program?.name}
            </Heading>
          </Flex>
        ) : null}
        <Flex gap={2}>
          <Text variant="labelS">Intake:</Text>
          <Text variant="bodyS">{program?.programIntakeTerm?.name ?? '--'}</Text>
        </Flex>
        <Flex gap={2}>
          <Text variant="labelS">Campus:</Text>
          <Text variant="bodyS">{program?.campus?.name ?? '--'}</Text>
        </Flex>
        <Flex gap={2}>
          <Text variant="labelS">Application ID:</Text>
          <Text variant="bodyS">{application?.attributes?.humanReadableId ?? '--'}</Text>
        </Flex>
        <Flex gap={2}>
          <Text variant="labelS">Application fee:</Text>
          {hasApplicationFee(application) ? (
            <Text variant="bodyS">
              {Intl.NumberFormat(undefined, {
                style: 'currency',
                currency: application?.attributes?.applicationFee?.currency || 'CAD',
              }).format(application?.attributes?.applicationFee?.amount || 0)}
            </Text>
          ) : (
            <Text variant="bodyS">No Fee</Text>
          )}
        </Flex>

        {application?.attributes?.applicationInformation?.submittedAt ? (
          <Flex gap={2}>
            <Text variant="labelS">Submission Date:</Text>
            <Text variant="bodyS">
              {format(
                new Date(application.attributes.applicationInformation.submittedAt),
                'MMMM d, yyyy',
              )}
            </Text>
          </Flex>
        ) : null}

        <Flex>
          <ReviewRequirementsButton programId={program?.program?.id as string} />
        </Flex>
      </Flex>
    </Card>
  )
}
