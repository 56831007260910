import { Button, Dialog, Divider, Flex } from '@applyboard/crystal-ui'
import { FileHistoryEntry } from './FileHistoryEntry'
import { useState } from 'react'
import { ApplicationDocument } from '../ApplicationDocuments/ApplicationDocument'

type FileHistoryDialogProps = {
  historicalRecord: Array<ApplicationDocument>
}

export function FileHistoryDialog(props: FileHistoryDialogProps) {
  const [open, setOpen] = useState(false)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>
        <Button emphasis="highlighted" intent="secondary">
          View History
        </Button>
      </Dialog.Trigger>
      <Dialog.Heading>Document History</Dialog.Heading>
      <Dialog.Content>
        <Flex direction="column">
          {props.historicalRecord.map((rec, idx) => {
            return (
              <Flex direction="column" key={idx}>
                {idx === 0 ? null : <Divider />}
                <FileHistoryEntry key={`file-history-${idx}`} record={rec} />
              </Flex>
            )
          })}
        </Flex>
      </Dialog.Content>
    </Dialog>
  )
}
