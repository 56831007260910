import React from 'react';
import { MultiCombobox } from '../../../components/uie-fix'
import { usePageProps } from '../../../context';
import { ApplicationsPageProps, useFilterField } from '../../../hooks';

export const IntakeTermFilter = () => {
  const intakeTermFilter = useFilterField('intakeTermId');
  const { referenceData } = usePageProps<ApplicationsPageProps>();

  return (
    <MultiCombobox
      value={intakeTermFilter.value}
      aria-label="Intake Term"
      placeholder="Intake Term"
      onChange={(selectedItems) => intakeTermFilter.setValue(selectedItems.filter((s) => !!s.trim()))}
      placeholderVariant="indicator"
    >
      {referenceData.intakeTerms.map(intakeTerm => (
        <MultiCombobox.Option label={intakeTerm.attributes.name} value={intakeTerm.id} key={intakeTerm.id} />
      ))}
    </MultiCombobox>
  );
};
