import { Dispatch, SetStateAction, useEffect, useState, useRef } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

type UseDebouncedStateReturnValue<T> = [T, T, Dispatch<SetStateAction<T>>];
export function useDebouncedState<T = unknown>(
  defaultState: T,
  delayOrOptions: number | { delay?: number; onChange?: () => void } = 200
): UseDebouncedStateReturnValue<T> {
  const delay = typeof delayOrOptions === 'number' ? delayOrOptions : delayOrOptions?.delay || 200;

  const onChangeRef = useRef(typeof delayOrOptions === 'object' && delayOrOptions?.onChange);
  onChangeRef.current = typeof delayOrOptions === 'object' && delayOrOptions?.onChange;

  const [value, setValue] = useState<T>(defaultState);
  const [debouncedValue, setDebouncedValue] = useState<T>(defaultState);

  useEffect(() => {
    if (value === debouncedValue) {
      return;
    }

    const timeoutId = setTimeout(() => {
      // Even though this function is prefixed with "unstable" the react
      // team has recommended using it where appropriate.  See:
      // https://github.com/facebook/react/issues/16387#issuecomment-521623662
      unstable_batchedUpdates(() => {
        setDebouncedValue(value);
        if (typeof onChangeRef.current === 'function') {
          onChangeRef.current();
        }
      });
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [value, delay, debouncedValue]);

  return [value, debouncedValue, setValue];
}
