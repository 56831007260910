import { VersionedApplicationResource } from 'applications-types-lib'

/**
 * Function that programmatically determines if the application has a fee.
 */
export function hasApplicationFee(application: VersionedApplicationResource | null): boolean {
  const applicationFeeAmount = application?.attributes?.applicationFee?.amount

  return applicationFeeAmount !== undefined && applicationFeeAmount > 0
}
