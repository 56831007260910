import { useAuthMutation } from '../auth'
import { ApiAgentRequest, request } from '../../utils'

type DeleteApplicationParams = {
  id?: string
}

export function useDeleteApplication() {
  const { isPending, mutate } = useAuthMutation(async (params: DeleteApplicationParams) => {
    return await request(
      new ApiAgentRequest(`/applications/${params.id}`, {
        method: 'DELETE',
      }),
      {
        isExpectedResponse: (res: unknown): res is any => true,
      },
    )
  })

  return {
    isDeletingApplication: isPending,
    deleteApplication: mutate,
  }
}
