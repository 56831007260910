import { Card, Flex, Heading, Tag, TagProps, Text } from '@applyboard/crystal-ui'
import { IAdditionalDocumentsRequest, IDocumentButton } from './types'

import { DocumentTags } from '../../../utils/enums'
import { AdditionalDocumentsDialog } from './AdditionalDocumentsDialog'

import { PassportDialog } from './PassportDialog'
import { LanguageTestDocumentsDialog } from './LanguageTestDocumentsDialog'
import { EducationHistoryDocumentsDialog } from './EducationHistoryDocumentsDialog'
import { format } from 'date-fns'
import { ApplicationForm } from '../ApplicationForms/ApplicationForm'

export function AdditionalDocumentsRequest({
  id,
  requestedAt,
  requestStatus,
  closedDate,
  documents,
  application,
}: IAdditionalDocumentsRequest) {
  return (
    <Card>
      <Card.Header>
        <Flex justify="between" align="center">
          <Flex direction="column">
            <Text variant="bodyS">{format(requestedAt, 'dd MMM yyyy').toLocaleUpperCase()}</Text>
            <Flex
              align={{ xs: 'start', md: 'center' }}
              gap={2}
              direction={{ xs: 'column', md: 'row' }}
            >
              <Heading level={2} variant="titleM">
                Additional Documents Requested
              </Heading>
              <RequestStatusTag requestStatus={requestStatus} closedDate={closedDate} />
            </Flex>
          </Flex>
        </Flex>
      </Card.Header>
      <Card.Divider mt={2} mb={2} />
      <Flex direction="column" gap={4}>
        <Text variant="bodyM">Please provide the following documents to complete the request.</Text>
        <Flex wrap={true} gap={4}>
          {documents.map((doc, index) => (
            <ApplicationForm key={index}>
              <DocumentButton
                index={index}
                id={id}
                document={doc}
                application={application}
                requestStatus={requestStatus}
              />
            </ApplicationForm>
          ))}
        </Flex>
      </Flex>
    </Card>
  )
}

function DocumentButton(props: IDocumentButton) {
  if (!props.document.type) return null

  const docRequested = {
    requestStatus: props.requestStatus,
    isDocCompleted: !!props.document?.fileDataIds?.length,
    documentType: props.document.type as unknown as DocumentTags,
    note: props.document.comment,
    sectionReference: props.document.sectionReference,
  }

  switch (docRequested.documentType) {
    case DocumentTags.LANGUAGE_TEST:
    case DocumentTags.LANGUAGE_TEST_IELTS:
    case DocumentTags.LANGUAGE_TEST_PTE:
    case DocumentTags.LANGUAGE_TEST_TOEFL:
      return (
        <LanguageTestDocumentsDialog
          id={props.id}
          document={docRequested}
          application={props.application}
          index={props.index}
        />
      )
    case DocumentTags.CERTIFICATE:
    case DocumentTags.TRANSCRIPTS:
      return (
        <EducationHistoryDocumentsDialog
          id={props.id}
          document={docRequested}
          application={props.application}
          index={props.index}
        />
      )
    case DocumentTags.PASSPORT:
      return (
        <PassportDialog
          id={props.id}
          index={props.index}
          document={docRequested}
          application={props.application}
        />
      )
    default:
      return (
        <AdditionalDocumentsDialog
          id={props.id}
          document={docRequested}
          application={props.application}
          index={props.index}
        />
      )
  }
}

type RequestStatusTagProps = Pick<IAdditionalDocumentsRequest, 'requestStatus' | 'closedDate'>

const RequestStatusTag = (props: RequestStatusTagProps) => {
  let tagIntent = 'warning'
  let tagLabel = 'Pending'

  switch (props.requestStatus) {
    case 'DISMISSED':
      tagIntent = 'secondary'
      tagLabel = `Dismissed${props.closedDate ? ` on ${format(props.closedDate, 'dd MMM yyyy').toLocaleUpperCase()}` : ''
        }`
      break

    case 'COMPLETED':
      tagIntent = 'positive'
      tagLabel = `Completed${props.closedDate ? ` on ${format(props.closedDate, 'dd MMM yyyy').toLocaleUpperCase()}` : ''
        }`
      break
  }

  return <Tag intent={tagIntent as TagProps['intent']}>{tagLabel}</Tag>
}
