import { Chip, ChipGroup, Flex } from "@applyboard/crystal-ui";
import { useFilterSummary, useGetPrograms } from "../../../hooks";
import { useQueryParameter } from "../../../context";

export function FilterSummary() {
  const { programs } = useGetPrograms({
    'page[number]': 1,
    'page[size]': 10000,
  }, { enabled: true })
  const { filterSummaryItems, removeValue } = useFilterSummary({ programs });
  const { resetQueryParameters } = useQueryParameter()

  if(!filterSummaryItems.length) return null

  return (
    <section aria-label="Application Filter Summary">
      <Flex  gap={1} pt={2} justify="between" align="center">
        <ChipGroup size="sm" width="hug" lines={1} variant="outlined">
          {filterSummaryItems.map((summaryItem) => {
            return (
              <Chip
                key={crypto.randomUUID()}
                onDelete={() => {
                  removeValue(summaryItem.filterKey, summaryItem.value)
                }}
              >
                {summaryItem.label}
              </Chip>
            )
          })}
          <ChipGroup.ClearAllButton
            key="clear-all"
            onClick={() => {
              resetQueryParameters();
            }}
          />
        </ChipGroup>
      </Flex>
    </section>
  );
}
