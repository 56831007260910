import { useMemo } from "react";
import { QueryDefinition, QueryDefinitions } from "../../context";
import { GetApplicationsQuerys, GetApplicationSearchQuery, ApplicationState } from "./types";
import { ApplicationSortableFields } from "@backend/resources/application";

export const MIN_SUBMISSION_DATE_FILTER_VALUE = '1/1/2022, 12:00:00 AM';

interface ListApplicationDefinition<K extends keyof GetApplicationsQuerys> extends QueryDefinition<GetApplicationsQuerys, K, GetApplicationSearchQuery> {}

const intakeTermIdQueryDefinition: ListApplicationDefinition<"intakeTermId"> = {
  getDefaultValue: (searchParams) => [...searchParams.getAll('intakeTermId')],
  toSearchQuery: (value) => ({ 'filter[intakeTermId]': value }),
}

const nationalityQueryDefinition: ListApplicationDefinition<"nationality"> = {
  getDefaultValue: (searchParams) => [...searchParams.getAll('nationality')],
  toSearchQuery: (value) => ({ 'filter[nationality]': value }),
}

const pageNumberQueryDefinition: ListApplicationDefinition<"pageNumber"> = {
  getDefaultValue: (searchParams) => parseInt(searchParams.get('pageNumber') ?? '1'),
  toSearchQuery: (value) => ({ 'page[number]': !!value ? value : 1 }),
}

const pageSizeQueryDefinition: ListApplicationDefinition<"pageSize"> = {
  getDefaultValue: (searchParams) => {
    const pageSize = searchParams.get('pageSize')

    if (pageSize && (pageSize === '20' || pageSize === '50' || pageSize === '75')) {
      return parseInt(pageSize)
    }

    return 20
  },
  toSearchQuery: (value) => ({ 'page[size]': !!value ? value : 20 }),
}

const programIdQueryDefinition: ListApplicationDefinition<"programId"> = {
  getDefaultValue: (searchParams) => [...searchParams.getAll('programId')],
  toSearchQuery: (value) => ({ 'filter[programId]': value }),
}

const searchQueryDefinition: ListApplicationDefinition<"searchQuery"> = {
  getDefaultValue: (searchParams) => searchParams.get('searchQuery') ?? '',
  toSearchQuery: (value) => ({ 'filter[q]': value }),
}

export const sortQueryDefinition: ListApplicationDefinition<"sort"> = {
  getDefaultValue: (searchParams) => (searchParams.get('sort') as ApplicationSortableFields) ?? '-submittedAt',
  toSearchQuery: (value) => ({ 'sort': value }),
}

const statusQueryDefinition: ListApplicationDefinition<"status"> = {
  getDefaultValue: (searchParams) => [...(searchParams.getAll('status') as ApplicationState[])],
  toSearchQuery: (value) => ({ 'filter[status]': value }),
}

const submissionDateQueryDefinition: ListApplicationDefinition<'submissionDate'> = {
  getDefaultValue: (searchParams) => {
    const submissionDateFrom = searchParams.get('submissionDate')
    const submissionDateTo = searchParams.get('submissionDateEnd')

    const submissionDate: { from?: string, to?: string } = {}
    if (submissionDateFrom) {
      submissionDate.from = decodeURIComponent(submissionDateFrom)
    }
    if (submissionDateTo) {
      submissionDate.to = decodeURIComponent(submissionDateTo)
    }

    return submissionDate
  },
  toSearchQuery: (value) => {
    let valueFrom = undefined
    let valueTo = undefined

    if (value.from && value.from !== 'undefined') {
      const fromDate = new Date(value.from)
      valueFrom = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), 12, 0, 0, 0).toISOString()
    }

    if (value.to && value.to !== 'undefined') {
      const toDate = new Date(new Date(value.to))
      toDate.setDate(toDate.getDate() + 1) // upper bound increased in query to maintain current behaviour
      valueTo = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 12, 0, 0, 0).toISOString()
    }

    return {
      'filter[submittedAt][gt]': valueFrom,
      'filter[submittedAt][lt]': valueTo,
    }
  },
}

export function useGetApplicationsQueryDefinitions() {
  const result = useMemo(() => {
    const definitions: QueryDefinitions<GetApplicationsQuerys, GetApplicationSearchQuery> = {
      intakeTermId: intakeTermIdQueryDefinition,
      nationality: nationalityQueryDefinition,
      pageNumber: pageNumberQueryDefinition,
      pageSize: pageSizeQueryDefinition,
      programId: programIdQueryDefinition,
      searchQuery: searchQueryDefinition,
      sort: sortQueryDefinition,
      status: statusQueryDefinition,
      submissionDate: submissionDateQueryDefinition,
    }

    const definitionsAsArray = Object.entries(definitions).map(([key, parameter]) => {
      return { key: key as keyof GetApplicationsQuerys, ...parameter};
    })

    return {
      definitions,
      definitionsAsArray
    };
  }, [])

  return result
}
