import { useAuthQuery } from './auth'
import { ApiAgentRequest, request } from '../utils/request'
import { AgentResource } from '@backend/resources/agent'

type GetAgentProps = {
  id?: string
}

type RawAgentResponse = {
  data: AgentResource
}

export function useGetAgent(props: GetAgentProps) {
  const { isLoading, data, isFetching } = useAuthQuery(
    ['agent', props.id],
    async () => {
      return await request<RawAgentResponse>(
        new ApiAgentRequest(`/agents/${props.id}`, {
          method: 'GET',
        }),
        {
          isExpectedResponse: (res): res is RawAgentResponse => res,
        },
      )
    },
    {
      enabled: !!props.id,
      refetchOnWindowFocus: false,
    },
  )

  return {
    isLoadingAgent: isLoading,
    isFetchingAgent: isFetching,
    agent: data?.data || null,
  }
}
