import { useState } from 'react'
import { OnBoardingBox } from '../../components/OnBoardingBox'
import { CongratulationsConfirmationCard } from './CongratulationsConfirmationCard'
import { SignUpConfirmationForm } from './SignUpConfirmationForm'
import { useSearchParams, useParams } from 'react-router-dom'
import { SignUpExpiredPage } from './SignUpExpiredPage'
import { useValidateCode } from '../../hooks'

export function SignUpConfirmationPage() {
  const { username } = useParams()
  const [showCongratulations, setShowCongratulations] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [searchParams] = useSearchParams()
  const expiryDate = searchParams?.get('expiry')
  const code = searchParams?.get('code')
  const emailParam = searchParams?.get('email')
  const email = emailParam ? decodeURIComponent(emailParam) : ''
  const { isValidCode, isLoading } = useValidateCode(code ?? '', email, expiryDate)

  if (isLoading) {
    return <OnBoardingBox />
  }

  if (!isValidCode) {
    return <SignUpExpiredPage username={username ?? ''} />
  }

  return (
    <OnBoardingBox>
      {showCongratulations ? (
        <CongratulationsConfirmationCard newPassword={newPassword} />
      ) : (
        <SignUpConfirmationForm
          onSuccess={newPassword => {
            setNewPassword(newPassword)
            setShowCongratulations(true)
          }}
        />
      )}
    </OnBoardingBox>
  )
}
