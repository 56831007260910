import { Flex, Text } from '@applyboard/crystal-ui'
import { formatAsDocumentDate } from '../../../utils/formatAsDocumentDate'
import { parseISO } from 'date-fns'
import { PassportHistoryRecord } from '../ApplicationDocuments'

export function PassportHistoryEntryFields({ record }: { record: PassportHistoryRecord }) {
  return (
    <Flex gap={4} direction="row" wrap>
      <Flex.Item basis={'calc(50% - 8px)'}>
        <Flex direction="column">
          <Text variant="labelS">Passport Number</Text>
          <Text variant="bodyS">{record.context?.passportNumber || '---'}</Text>
        </Flex>
      </Flex.Item>
      <Flex.Item basis={'calc(50% - 8px)'}>
        <Flex direction="column">
          <Text variant="labelS">Expiry Date</Text>
          <Text variant="bodyS">
            {record.context?.passportExpiry
              ? formatAsDocumentDate(parseISO(record.context?.passportExpiry))
              : '---'}
          </Text>
        </Flex>
      </Flex.Item>
    </Flex>
  )
}
