import { ApplicationsPageProps, useGetApplicationsQuery } from "../../../hooks"
import { ApplicationsPagination } from "../ApplicationsPagination"
import { ApplicationsTable } from "./ApplicationsTable"
import { ApplicationCards } from "./ApplicationCards"
import { EmptyApplicationList } from "../EmptyApplicationList"
import { usePageProps } from "../../../context"

export function ApplicationsList() {
  const { referenceData } = usePageProps<ApplicationsPageProps>();
  const { applications, isFetchingApplications, totalApplications } = useGetApplicationsQuery({ enabled: referenceData.hasApplications })
  if(!referenceData.hasApplications) {
    return <EmptyApplicationList />
  }

  return (
    <>
      <ApplicationCards applications={applications} />
      <ApplicationsTable applications={applications} isFetchingApplications={isFetchingApplications} />
      <ApplicationsPagination totalApplications={totalApplications} isFetchingApplications={isFetchingApplications} />
    </>
  )
}
