import { Flex } from "@applyboard/crystal-ui";
import { QueryFilter } from "./QueryFilter";
import { NationalityFilter } from "./NationalityFilter";
import { ProgramFilter } from "./ProgramFilter";
import { IntakeTermFilter } from "./IntakeTermFilter";
import { StatusFilter } from "./StatusFilter";
import { SubmissionDateFilter } from "./SubmissionDateFilter";
import { FilterSummary } from "./FilterSummary";

export function DesktopFilters() {
  return (
    <Flex gap={3} pt={2} direction={'column'} wrap={true}>
      <Flex.Item grow={1}>
        <QueryFilter />
      </Flex.Item>
      <Flex.Item grow={1}>
        <Flex gap={2} justify="start" wrap={true}>
          <Flex.Item><NationalityFilter /></Flex.Item>
          <Flex.Item><ProgramFilter /></Flex.Item>
          <Flex.Item><IntakeTermFilter /></Flex.Item>
          <Flex.Item><StatusFilter /></Flex.Item>
          <Flex.Item grow={1} basis={'450px'}><SubmissionDateFilter /></Flex.Item>
        </Flex>
      </Flex.Item>
      <FilterSummary/>
    </Flex>
  )
}
